import { Collapse } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import { FC } from 'react';
type Props = {
  items: any;
};
const CollapsibleContent: FC<Props> = ({ items }) => {
  return (
    <Collapse
      expandIcon={({ isActive }) => (
        <CaretRightOutlined rotate={isActive ? 90 : 0} />
      )}
      items={items}
      size="small"
      bordered={false}
      ghost
      defaultActiveKey={'1'}
    />
  );
};
export default CollapsibleContent;
