import {
  Button,
  Col,
  DatePicker,
  Form,
  FormProps,
  Input,
  InputNumber,
  Row,
  Select,
  message,
} from 'antd';
import { FC, useEffect } from 'react';
import { NamedItem, LandParcel } from '../../../types/types';
import { baseFormLayout, fullWidth } from '../../../shared/styles/baseStyle';
import LoadingSpin from '../../LoadingSpin/LoadingSpin';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getData, mutateData } from '../../../shared/services/apiServices';
import useCreateAxiosInstance from '../../../shared/hooks/useCreateAxiosInstance';

import endPoints from '../../../shared/endPoints.json';
type Props = {
  selectedCultivation: LandParcel | undefined;
  setActivityAddition: React.Dispatch<React.SetStateAction<boolean>>;
  activityAddition: boolean;
};
const PlantProtectionForm: FC<Props> = ({
  selectedCultivation,
  setActivityAddition,
  activityAddition,
}) => {
  const { request } = useCreateAxiosInstance();
  const [form] = Form.useForm();
  const dateFormat = 'DD/MM/YYYY';
  const requiredRuleConfig = {
    required: true,
    message: 'Υποχρεωτικό πεδίο',
  };
  useEffect(() => {
    form.setFieldsValue({
      cultivation: selectedCultivation?.cultivation,
      landParcelId: selectedCultivation?.id,
    });
  }, [selectedCultivation]);

  const { data: weatherItems, isLoading: weatherItemsLoading } = useQuery({
    queryKey: ['weatherItems'],
    queryFn: () => getData(request, endPoints.CALENDAR.WEATHER),
    select(data: { data: NamedItem[] }) {
      return data.data.map((item: NamedItem) => ({
        value: item.id,
        label: item.name,
      }));
    },
  });
  const { data: doseUnitItems, isLoading: doseUnitItemsLoading } = useQuery({
    queryKey: ['doseUnitItems'],
    queryFn: () => getData(request, endPoints.CALENDAR.DOSEUNIT),
    select(data: { data: NamedItem[] }) {
      return data.data.map((item: NamedItem) => ({
        value: item.id,
        label: item.name,
      }));
    },
  });

  const { mutate } = useMutation({
    mutationFn: (formValues) =>
      mutateData(
        request,
        endPoints.CALENDAR.LANDPARCELPLANTPROTECTION,
        formValues,
        'post',
      ),
    onSuccess() {
      message.success('Η ενέργεια προστέθηκε με επιτυχία!');
      form.resetFields([
        'plantProtectionDate',
        'weatherId',
        'activeSubstance',
        'commercialFormulation',
        'applicationDose',
        'doseUnitId',
        'perimeterOfSafety',
        'volumeOfSpraySolution',
        'harvestAbility',
        'observations',
      ]);
      setActivityAddition(!activityAddition);
    },
  });
  const onFinish: FormProps['onFinish'] = async (values: any) => {
    const formattedValues = {
      ...values,
      plantProtectionDate: values.plantProtectionDate?.$d.getTime(),
    };
    mutate(formattedValues);
  };
  if (doseUnitItemsLoading || weatherItemsLoading) return <LoadingSpin />;
  return (
    <Form
      {...baseFormLayout}
      layout="vertical"
      size="large"
      form={form}
      onFinish={onFinish}
    >
      <Row>
        <Col xs={12}>
          <Form.Item name="landParcelId" label="Κωδικός Αγροτεμαχίου">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col xs={12}>
          <Form.Item name="cultivation" label="Καλλιέργεια">
            <Input disabled />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Form.Item
            name="plantProtectionDate"
            label="Ημερομηνία"
            rules={[requiredRuleConfig]}
          >
            <DatePicker
              format={dateFormat}
              placeholder="Ημερομηνία"
              style={fullWidth}
            />
          </Form.Item>
        </Col>
        <Col xs={12}>
          <Form.Item
            name="weatherId"
            label="Καιρός κατά την Εφαρμογή"
            rules={[requiredRuleConfig]}
          >
            <Select
              allowClear
              options={weatherItems}
              placeholder="Καιρός κατά την Εφαρμογή"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Form.Item
            name="activeSubstance"
            label="Δραστική ουσία"
            rules={[requiredRuleConfig]}
          >
            <Input placeholder="Δραστική ουσία" />
          </Form.Item>
        </Col>
        <Col xs={12}>
          <Form.Item
            name="commercialFormulation"
            label="Εμπορικό σκεύασμα"
            rules={[requiredRuleConfig]}
          >
            <Input placeholder="Εμπορικό σκεύασμα" />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="start">
        <Col xs={12}>
          <Form.Item
            name="perimeterOfSafety"
            label="Όρια Ασφάλειας (PHI)"
            rules={[requiredRuleConfig]}
          >
            <Input placeholder="Όρια Ασφάλειας (PHI)" />
          </Form.Item>
        </Col>
        <Col xs={12}>
          <Form.Item
            name="doseUnitId"
            label="Μονάδα Δόσης "
            rules={[requiredRuleConfig]}
          >
            <Select
              allowClear
              options={doseUnitItems}
              placeholder="Μονάδα Δόσης "
            />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="center">
        <Col span={12}>
          <Form.Item
            name="applicationDose"
            label="Δόση εφαρμογής "
            rules={[requiredRuleConfig]}
          >
            <InputNumber
              type="number"
              style={fullWidth}
              step={1}
              min={0}
              placeholder="Δόση εφαρμογής "
            />
          </Form.Item>
        </Col>
        <Col xs={12}>
          <Form.Item
            name="harvestAbility"
            label="Δυνατότητα Συγκομιδής"
            rules={[requiredRuleConfig]}
          >
            <Input placeholder="Δυνατότητα Συγκομιδής" />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Form.Item
            name="volumeOfSpraySolution"
            label="Όγκος Ψεκαστικού Διαλύματος (lt/ha)"
            rules={[requiredRuleConfig]}
          >
            <InputNumber
              type="number"
              style={fullWidth}
              step={1}
              min={0}
              placeholder="Όγκος Ψεκαστικού Διαλύματος"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="start">
        <Col span={12}></Col>
      </Row>
      <Row>
        <Col xs={24}>
          <Form.Item
            name="observations"
            label="Σημειώσεις/Παρατηρήσεις"
            rules={[requiredRuleConfig]}
          >
            <Input.TextArea rows={3} placeholder="Σημειώσεις/Παρατηρήσεις" />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="center">
        <Button htmlType="submit">Προσθήκη</Button>
      </Row>
    </Form>
  );
};
export default PlantProtectionForm;
