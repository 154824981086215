import { FC, useContext } from 'react';
import {
  Button,
  Col,
  Dropdown,
  Layout,
  MenuProps,
  Row,
  Space,
  Typography,
} from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import { UserOutlined } from '@ant-design/icons';
import { AuthContext } from '../../shared/context/auth-context';
import { headerText } from './style';
import { useNavigate } from 'react-router-dom';
const Header: FC = () => {
  const navigate = useNavigate();
  const { Text } = Typography;
  const { Header } = Layout;
  const { logout } = useContext(AuthContext);

  const items = [
    {
      label: 'Συνδρομές',
      key: '0',
    },
    {
      label: 'Αλλαγή στοιχείων πρόσβασης',
      key: '/profile',
    },
  ];
  const handleMenuClick: MenuProps['onClick'] = (e) => {
    navigate(e.key);
  };
  return (
    <Header>
      <Row>
        <Col span={22}>
          <Text style={headerText}></Text>
        </Col>
        <Col>
          <Space>
            <Dropdown
              menu={{
                items,
                onClick: handleMenuClick,
              }}
            >
              <Button
                shape="circle"
                type="primary"
                icon={<UserOutlined />}
              ></Button>
            </Dropdown>
            <Button
              shape="circle"
              type="primary"
              icon={<LogoutOutlined />}
              onClick={logout}
            ></Button>
          </Space>
        </Col>
      </Row>
    </Header>
  );
};
export default Header;
