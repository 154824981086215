import { FC, useState } from 'react';
import NutrientsBalanceInputTable from '../Tables/NutrientsBalanceInputTable';
import NutrientsBalanceOutputTable from '../Tables/NutrientsBalanceOutputTable';
import CollapsibleContent from '../CollapsibleContent/CollapsibleContent';
import CultivationForm from '../Forms/Cultivation/CultivationForm';
import { CultivationDetails } from '../../types/types';
import G3Form from '../Forms/NutrientsBalance/G3Form';
import G3Table from '../Tables/G3Table';
import { Divider } from 'antd';
import UploadFile from '../UploadFile/UploadFile';

type Props = {
  selectedCultivation: CultivationDetails;
};
const CultivationsViewList: FC<Props> = ({ selectedCultivation }) => {
  const [g3MutationSettled, setG3MutationSettled] = useState<boolean>(false);
  const NutrientsBalanceItems = [
    {
      key: '1',
      label: 'Πληροφορίες καλλιέργειας',
      children: (
        <CultivationForm
          selectedCultivationDetails={selectedCultivation}
          apiMethod="post"
          setIsEditCatalogueModalOpen={() => {
            // intentionally empty
          }}
          viewOnly
        />
      ),
    },
    {
      key: '2',
      label: 'Θρεπτικά συστατικά / Ανθρακικό αποτύπωμα',
      children: (
        <>
          {selectedCultivation?.balance ? (
            <>
              <NutrientsBalanceInputTable
                balanceInput={selectedCultivation?.balance?.input}
              />
              <NutrientsBalanceOutputTable
                balanceOutput={selectedCultivation?.balance?.output}
              />
              <G3Form
                landParcelId={selectedCultivation?.id}
                setG3MutationSettled={setG3MutationSettled}
              />
              <Divider />
              <G3Table g3MutationSettled={g3MutationSettled} />
            </>
          ) : (
            <>Δεν υπάρχουν δεδομένα</>
          )}
        </>
      ),
    },
    {
      key: '3',
      label: 'Εδαφολογική Ανάλυση',
      children: (
        <UploadFile
          selectedCultivationId={selectedCultivation?.id}
          pdfFileName={selectedCultivation?.pdfFileName}
        />
      ),
    },
  ];
  return <CollapsibleContent items={NutrientsBalanceItems} />;
};
export default CultivationsViewList;
