import {
  Button,
  Col,
  DatePicker,
  Form,
  FormProps,
  Input,
  InputNumber,
  Row,
  Select,
  message,
} from 'antd';
import { FC, useEffect } from 'react';
import { NamedItem, LandParcel } from '../../../types/types';
import { baseFormLayout, fullWidth } from '../../../shared/styles/baseStyle';
import LoadingSpin from '../../LoadingSpin/LoadingSpin';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getData, mutateData } from '../../../shared/services/apiServices';
import useCreateAxiosInstance from '../../../shared/hooks/useCreateAxiosInstance';
import endPoints from '../../../shared/endPoints.json';
type Props = {
  selectedCultivation: LandParcel | undefined;
  setActivityAddition: React.Dispatch<React.SetStateAction<boolean>>;
  activityAddition: boolean;
};
const HarvestForm: FC<Props> = ({
  selectedCultivation,
  setActivityAddition,
  activityAddition,
}) => {
  const [messageApi, contextHolder] = message.useMessage();
  const { request } = useCreateAxiosInstance();
  const [form] = Form.useForm();
  const dateFormat = 'DD/MM/YYYY';
  const requiredRuleConfig = {
    required: true,
    message: 'Υποχρεωτικό πεδίο',
  };
  useEffect(() => {
    form.setFieldsValue({
      cultivation: selectedCultivation?.cultivation,
      landParcelId: selectedCultivation?.id,
    });
  }, [selectedCultivation]);
  const { data: harvestMethodItems, isLoading } = useQuery({
    queryKey: ['harvestMethodItems'],
    queryFn: () => getData(request, endPoints.CALENDAR.HARVEST),
    select(data) {
      return data.data.map((item: NamedItem) => ({
        value: item.id,
        label: item.name,
      }));
    },
  });
  const { mutate } = useMutation({
    mutationFn: (formValues) =>
      mutateData(
        request,
        endPoints.CALENDAR.LANDPARCELHARVEST,
        formValues,
        'post',
      ),
    onSuccess() {
      messageApi.success('Η ενέργεια προστέθηκε με επιτυχία!');
      form.resetFields([
        'harvestMethodId',
        'preEstimatePerHectare',
        'preEstimateTotal',
        'finalDeliverableQuantity',
        'difference',
        'preEstimatedDate',
        'harvestDate',
      ]);
      setActivityAddition(!activityAddition);
    },
  });

  const onFinish: FormProps['onFinish'] = async (values: any) => {
    const formattedValues = {
      ...values,
      preEstimatedDate: values.preEstimatedDate?.$d.getTime(),
      harvestDate: values.harvestDate?.$d.getTime(),
    };

    mutate(formattedValues);
  };
  if (isLoading) return <LoadingSpin />;
  return (
    <>
      {contextHolder}
      <Form
        {...baseFormLayout}
        layout="vertical"
        size="large"
        form={form}
        onFinish={onFinish}
      >
        <Row>
          <Col xs={12}>
            <Form.Item name="landParcelId" label="Κωδικός Αγροτεμαχίου">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item name="cultivation" label="Καλλιέργεια">
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Form.Item
              name="preEstimatedDate"
              label="Ημ/νια Προεκτίμησης"
              rules={[requiredRuleConfig]}
            >
              <DatePicker
                format={dateFormat}
                placeholder="Ημ/νια Προεκτίμησης"
                style={fullWidth}
              />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item
              name="preEstimatePerHectare"
              label="Προεκτίμηση ανά Εκτάριο (kg/ha)"
              rules={[requiredRuleConfig]}
            >
              <InputNumber
                type="number"
                style={fullWidth}
                step={1}
                min={0}
                placeholder="Προεκτίμηση ανά Εκτάριο (kg/ha)"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Form.Item
              name="preEstimateTotal"
              label="Προεκτίμηση Ολική (kg)"
              rules={[requiredRuleConfig]}
            >
              <InputNumber
                type="number"
                style={fullWidth}
                step={1}
                min={0}
                placeholder="Προεκτίμηση Ολική (kg)"
              />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item
              name="harvestDate"
              label="Ημ/νια Συγκομιδής"
              rules={[requiredRuleConfig]}
            >
              <DatePicker
                format={dateFormat}
                placeholder="Ημ/νια Συγκομιδής"
                style={fullWidth}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Form.Item
              name="harvestMethodId"
              label="Τρόπος Συγκομιδής"
              rules={[requiredRuleConfig]}
            >
              <Select
                allowClear
                options={harvestMethodItems}
                placeholder="Τρόπος Συγκομιδής"
              />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item
              name="difference"
              label="Διαφορά (%)"
              rules={[requiredRuleConfig]}
            >
              <InputNumber
                type="number"
                style={fullWidth}
                step={1}
                min={0}
                max={100}
                placeholder="Διαφορά (%)"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Form.Item
              name="finalDeliverableQuantity"
              label="Τελική Παραδοτέα Ποσότητα (kg)"
              rules={[requiredRuleConfig]}
            >
              <InputNumber
                type="number"
                style={fullWidth}
                step={1}
                min={0}
                placeholder="Τελική Παραδοτέα Ποσότητα (kg)"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="center">
          <Button htmlType="submit">Προσθήκη</Button>
        </Row>
      </Form>
    </>
  );
};

export default HarvestForm;
