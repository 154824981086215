import { FC } from 'react';
import { Table, Typography } from 'antd';
import { BalanceNutrients } from '../../types/types';
type Props = {
  balance: any;
  summaryDesciption: string;
};
const NutrientsBalanceTable: FC<Props> = ({ balance, summaryDesciption }) => {
  if (!balance) return <></>;

  const columns: any = [
    {
      title: '',
      dataIndex: 'row',
      key: 'row',
      rowScope: 'row',
    },
    {
      title: 'Άζωτο (μονάδες λίπανσης/εκτάριο)',
      dataIndex: 'n',
      key: 'n',
    },
    {
      title: 'Φώσφορος  (μονάδες λίπανσης/εκτάριο)',
      dataIndex: 'p',
      key: 'p',
    },
    {
      title: 'Κάλιο (μονάδες λίπανσης/εκτάριο)',
      dataIndex: 'k',
      key: 'k',
    },
    {
      title: 'Πεντοξείδιο του φωσφόρου  (μονάδες λίπανσης/εκτάριο)',
      dataIndex: 'p2O5',
      key: 'p2O5',
    },
    {
      title: 'Οξείδιο του Καλίου (μονάδες λίπανσης/εκτάριο)',
      dataIndex: 'k2O',
      key: 'k2O',
    },
  ];

  return (
    <Table
      dataSource={balance}
      columns={columns}
      size="small"
      pagination={false}
      style={{ overflowX: 'auto' }}
      summary={(pageData) => {
        let totalN = 0;
        let totalP = 0;
        let totalK = 0;
        let totalp2O5 = 0;
        let totalk2O = 0;
        pageData.forEach(({ n, p, k, p2O5, k2O }) => {
          totalN += n ? n : 0;
          totalP += p ? p : 0;
          totalK += k ? k : 0;
          totalp2O5 += p2O5 ? p2O5 : 0;
          totalk2O += k2O ? k2O : 0;
        });
        return (
          <>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0}>
                {summaryDesciption}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1}>
                <Typography.Text type="danger">
                  {totalN.toFixed(2)}
                </Typography.Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={2}>
                <Typography.Text type="danger">
                  {totalP.toFixed(2)}
                </Typography.Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={3}>
                <Typography.Text type="danger">
                  {totalK.toFixed(2)}
                </Typography.Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={4}>
                <Typography.Text type="danger">
                  {totalp2O5.toFixed(2)}
                </Typography.Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={5}>
                <Typography.Text type="danger">
                  {totalk2O.toFixed(2)}
                </Typography.Text>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </>
        );
      }}
    />
  );
};
export default NutrientsBalanceTable;
