import { Layout, Grid } from 'antd';
import { FC, useContext, useState } from 'react';
import {
  TeamOutlined,
  SnippetsOutlined,
  ScheduleOutlined,
  BugOutlined,
} from '@ant-design/icons';
import SiderMenu from './SiderMenu';
import { AuthContext } from '../../shared/context/auth-context';
const Sider: FC = () => {
  const { role } = useContext(AuthContext);
  const { useBreakpoint } = Grid;
  const { Sider } = Layout;
  const breakpoints = useBreakpoint();
  const [collapsed, setCollapsed] = useState(false);
  if (!role) return <></>;
  type SiderItem = {
    key: string;
    icon: JSX.Element;
    label: string;
    role: string[];
    children?: {
      key: string;
      label: string;
      role: string[];
    }[];
  };
  const siderItems: SiderItem[] = [
    {
      key: '1',
      icon: <TeamOutlined />,
      label: 'Χρήστες',
      role: ['Διαχειριστής'],
      children: [
        {
          key: '/users/add',
          label: `Προσθήκη`,
          role: ['Διαχειριστής'],
        },
        {
          key: '/users/catalogue',
          label: `Κατάλογος`,
          role: ['Διαχειριστής'],
        },
      ],
    },
    {
      key: '2',
      icon: <SnippetsOutlined />,
      label: 'Αγροτεμάχια',
      role: ['Διαχειριστής', 'Καλλιεργητής'],
      children: [
        {
          key: '/cultivations/add',
          label: `Προσθήκη`,
          role: ['Διαχειριστής', 'Καλλιεργητής'],
        },
        {
          key: '/cultivations/catalogue',
          label: `Κατάλογος`,
          role: ['Διαχειριστής', 'Καλλιεργητής'],
        },
      ],
    },
    {
      key: '/calendar',
      icon: <ScheduleOutlined />,
      label: 'Ημερολόγιο Αγρού',
      role: ['Διαχειριστής', 'Καλλιεργητής'],
    },
    {
      key: 'pesticides',
      icon: <BugOutlined />,
      label: 'Φυτοπροστατευτικά Σκευάσματα',
      role: ['Διαχειριστής', 'Καλλιεργητής'],
    },
  ];
  const siderItemsForRole = siderItems
    .map((item) => {
      const filteredChildren = item.children?.filter((child) =>
        child.role.includes(role),
      );
      if (item.role.includes(role)) {
        return {
          ...item,
          children: filteredChildren,
        };
      }
      return null;
    })
    .filter((item) => item !== null);

  return (
    <>
      {!breakpoints.md ? (
        <Sider breakpoint="md" collapsedWidth={0}>
          <SiderMenu items={siderItemsForRole} />
        </Sider>
      ) : (
        <Sider
          collapsible
          collapsed={collapsed}
          onCollapse={(value) => {
            setCollapsed(value);
          }}
        >
          <SiderMenu items={siderItemsForRole} />
        </Sider>
      )}
    </>
  );
};
export default Sider;
