import {
  Button,
  Col,
  DatePicker,
  Form,
  FormProps,
  Input,
  InputNumber,
  Row,
  Select,
  message,
} from 'antd';
import { FC, useEffect } from 'react';
import { NamedItem, LandParcel } from '../../../types/types';
import { baseFormLayout, fullWidth } from '../../../shared/styles/baseStyle';
import LoadingSpin from '../../LoadingSpin/LoadingSpin';
import useCreateAxiosInstance from '../../../shared/hooks/useCreateAxiosInstance';
import { getData, mutateData } from '../../../shared/services/apiServices';
import { useMutation, useQuery } from '@tanstack/react-query';
import endPoints from '../../../shared/endPoints.json';
type Props = {
  selectedCultivation: LandParcel | undefined;
  setActivityAddition: React.Dispatch<React.SetStateAction<boolean>>;
  activityAddition: boolean;
};
const CultivationCareForm: FC<Props> = ({
  selectedCultivation,
  setActivityAddition,
  activityAddition,
}) => {
  const { request } = useCreateAxiosInstance();
  const [form] = Form.useForm();
  const dateFormat = 'DD/MM/YYYY';
  const requiredRuleConfig = {
    required: true,
    message: 'Υποχρεωτικό πεδίο',
  };
  const [messageApi, contextHolder] = message.useMessage();
  useEffect(() => {
    form.setFieldsValue({
      cultivation: selectedCultivation?.cultivation,
      landParcelId: selectedCultivation?.id,
    });
  }, [selectedCultivation]);

  const { data: cultivationCareItems, isLoading } = useQuery({
    queryKey: ['cultivationCareItems'],
    queryFn: () => getData(request, endPoints.CALENDAR.CULTIVATIONCARE),
    select(data) {
      return data.data.map((item: NamedItem) => ({
        value: item.id,
        label: item.name,
      }));
    },
  });

  const { mutate } = useMutation({
    mutationFn: (formValues) =>
      mutateData(
        request,
        endPoints.CALENDAR.LANDPARCELCULTIVATIONCARE,
        formValues,
        'post',
      ),
    onSuccess() {
      messageApi.success('Η ενέργεια προστέθηκε με επιτυχία!');
      form.resetFields([
        'cultivationCareId',
        'startDate',
        'duration',
        'personnel',
      ]);
      setActivityAddition(!activityAddition);
    },
  });

  const onFinish: FormProps['onFinish'] = async (values: any) => {
    const formattedValues = {
      ...values,
      startDate: values.startDate?.$d.getTime(),
    };

    mutate(formattedValues);
  };
  if (isLoading) return <LoadingSpin />;
  return (
    <>
      {contextHolder}
      <Form
        {...baseFormLayout}
        layout="vertical"
        size="large"
        form={form}
        onFinish={onFinish}
      >
        <Row>
          <Col xs={12}>
            <Form.Item name="landParcelId" label="Κωδικός Αγροτεμαχίου">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item name="cultivation" label="Καλλιέργεια">
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Form.Item
              name="cultivationCareId"
              label="Καλλιεργητικές Φροντίδες"
              rules={[requiredRuleConfig]}
            >
              <Select
                allowClear
                options={cultivationCareItems}
                placeholder="Καλλιεργητικές Φροντίδες"
              />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item
              name="startDate"
              label="Ημ/νια Έναρξης"
              rules={[requiredRuleConfig]}
            >
              <DatePicker
                format={dateFormat}
                placeholder="Ημ/νια Έναρξης"
                style={fullWidth}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Form.Item
              name="duration"
              label="Διάρκεια (Ημέρες)"
              rules={[requiredRuleConfig]}
            >
              <InputNumber
                type="number"
                style={fullWidth}
                step={1}
                min={0}
                placeholder="Διάρκεια (Ημέρες)"
              />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item
              name="personnel"
              label="Προσωπικό (Αριθμός)"
              rules={[requiredRuleConfig]}
            >
              <InputNumber
                type="number"
                style={fullWidth}
                step={1}
                min={0}
                placeholder="Προσωπικό (Αριθμός)"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="center">
          <Button htmlType="submit">Προσθήκη</Button>
        </Row>
      </Form>
    </>
  );
};
export default CultivationCareForm;
