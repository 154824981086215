import { baseColors } from '../baseStyle';
export const config = {
  components: {
    Button: {
      defaultBg: baseColors.fsGreen,
      defaultColor: 'white',
      defaultActiveColor: 'white',
      borderRadius: 2,
      borderRadiusLG: 2,
      colorBorder: baseColors.fsDeepGreen,
      colorBgContainer: baseColors.fsDeepGreen,
      defaultHoverColor: 'white',
      defaultHoverBorderColor: baseColors.fsDeepGreen,
      colorPrimary: baseColors.fsGreen,
      colorPrimaryHover: baseColors.fsDeepGreen,
      colorLink: baseColors.fsGreen,
      colorLinkHover: baseColors.fsDeepGreen,
      colorLinkActive: baseColors.fsGrey,
      colorPrimaryActive: baseColors.fsDeepGreen,
    },
    Layout: {
      headerBg: baseColors.fsGrey,
      headerHeight: 90,
      bodyBg: baseColors.background,
      siderBg: baseColors.fsGreen,
      footerBg: baseColors.fsLightGrey,
      triggerBg: baseColors.fsGreen,
    },
    Input: {
      borderRadiusLG: 4,
      colorBgContainerDisabled: 'white',
      colorTextDisabled: 'black',
      colorPrimary: baseColors.fsDeepGreen,
      colorPrimaryHover: baseColors.fsDeepGreen,
    },
    InputNumber: {
      colorBgContainerDisabled: 'white',
      colorTextDisabled: 'black',
      colorPrimary: baseColors.fsDeepGreen,
      colorPrimaryHover: baseColors.fsDeepGreen,
    },
    Spin: {
      colorPrimary: baseColors.fsGreen,
    },
    Form: { verticalLabelPadding: 0, fontSize: 12 },
    Table: {
      rowSelectedBg: baseColors.fsLightGrey,
      rowSelectedHoverBg: baseColors.fsLightGrey,
    },
    Radio: {
      colorPrimary: baseColors.fsDeepGreen,
    },
    Pagination: {
      colorPrimary: baseColors.fsDeepGreen,
      colorPrimaryHover: baseColors.fsDeepGreen,
    },
    Select: {
      colorBgContainerDisabled: 'white',
      colorTextDisabled: 'black',
      colorPrimary: baseColors.fsDeepGreen,
      colorPrimaryHover: baseColors.fsDeepGreen,
    },
    DatePicker: {
      colorBgContainerDisabled: 'white',
      colorTextDisabled: 'black',
      colorPrimary: baseColors.fsDeepGreen,
      colorPrimaryHover: baseColors.fsDeepGreen,
    },
    Menu: {
      darkItemBg: baseColors.fsGreen,
      darkSubMenuItemBg: baseColors.fsGreen,
      colorPrimary: baseColors.fsDeepGreen,
    },
  },
};
