import { FC } from 'react';
import CultivationForm from '../../components/Forms/Cultivation/CultivationForm';

const AddCultivation: FC = () => {
  return (
    <CultivationForm
      selectedCultivationDetails={null}
      apiMethod="post"
      successTitle="Η καλλιέργεια προστέθηκε με επιτυχία!"
      setIsEditCatalogueModalOpen={() => {
        // intentionally empty
      }}
      viewOnly={false}
    />
  );
};

export default AddCultivation;
