import React, { useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useCreateAxiosInstance from './shared/hooks/useCreateAxiosInstance';
import { useMutation, useQuery } from '@tanstack/react-query';
import { mutateData, getData } from './shared/services/apiServices';
import endPoints from './shared/endPoints.json';
import LoadingSpin from './components/LoadingSpin/LoadingSpin';
import { AuthContext } from './shared/context/auth-context';
const Callback = () => {
  const { login } = useContext(AuthContext);
  const { request } = useCreateAxiosInstance();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const code = searchParams.get('code'); // Get authorization code from URL
  const error = searchParams.get('error'); // Get authorization code from URL

  console.log(code, error);

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['getGoogleToken'],
    queryFn: () =>
      getData(request, `${endPoints.USER.LOGINGOOGLE}?code=${code}`),
    enabled: false,
  });

  console.log('data', data);

  if (data) {
    login(data.data);
  }

  /*const { mutate } = useMutation({
    mutationFn: () =>
      mutateData(request, endPoints.USER.LOGINGOOGLE, code, 'post'),
    onSuccess() {
      console.log('sucess');
    },
  });*/

  useEffect(() => {
    if (error) {
      navigate('/login');
    }
    if (code) {
      refetch();
      console.log('code', code);
    }
  }, []);

  return <LoadingSpin />;
};
export default Callback;
