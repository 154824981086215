import { FC } from 'react';
import { Layout, Typography } from 'antd';
import { footerText } from './style';
const Footer: FC = () => {
  const { Footer } = Layout;
  return (
    <Footer>
      <Typography.Text style={footerText}>
        powered by Farmacon S.A
      </Typography.Text>
    </Footer>
  );
};
export default Footer;
