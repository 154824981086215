import {
  Button,
  Card,
  Col,
  Flex,
  Form,
  Input,
  Row,
  Typography,
  type FormProps,
  message,
} from 'antd';
import { LockOutlined, MailOutlined } from '@ant-design/icons';
import { FC, useContext } from 'react';
import { resetButton } from './style';
import { outerRow } from '../../shared/styles/baseStyle';
import { useMutation } from '@tanstack/react-query';
import { mutateData } from '../../shared/services/apiServices';
import useCreateAxiosInstance from '../../shared/hooks/useCreateAxiosInstance';
import endPoints from '../../shared/endPoints.json';
import { AuthContext } from '../../shared/context/auth-context';
import { useNavigate } from 'react-router-dom';

type FieldType = {
  newPassword: string;
  oldPassword: string;
  username: string;
  confirmPassword: string;
};
const Profile: FC = () => {
  const { uniqueName, logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const { Title } = Typography;
  const { request } = useCreateAxiosInstance();
  const { mutate } = useMutation({
    mutationFn: (values: any) =>
      mutateData(request, endPoints.USER.RESETPASSWORD, values, 'post'),
    onSuccess() {
      messageApi
        .open({
          type: 'success',
          content: 'Ολοκλήρωση αλλαγής! Συνδεθείτε ξανά στο λογαριασμό σας',
          duration: 2,
        })
        .then(() => {
          {
            uniqueName ? logout() : navigate('/login');
          }
        });
    },
  });

  const onFinish: FormProps<FieldType>['onFinish'] = async (values) => {
    mutate(values);
  };

  return (
    <>
      {contextHolder}
      <Row align="middle" justify="center" style={outerRow}>
        <Col xs={16} sm={14} md={14} lg={10} xl={10} xxl={6}>
          <Card>
            <Title level={3} style={{ marginBottom: 30, textAlign: 'center' }}>
              {'Αλλαγή '}
              {uniqueName ? 'Στοιχείων Πρόσβασης' : 'Προσωρινού Κωδικού'}
            </Title>
            <Col>
              <Form
                size="large"
                onFinish={onFinish}
                name="basic"
                initialValues={{ username: uniqueName }}
              >
                <Form.Item name="username">
                  <Input
                    prefix={<MailOutlined />}
                    disabled={!!uniqueName}
                    placeholder="Email"
                  />
                </Form.Item>
                <Form.Item
                  name="oldPassword"
                  rules={[
                    {
                      required: true,
                      message: 'Κωδικός Πρόσβασης!',
                    },
                  ]}
                >
                  <Input.Password
                    prefix={<LockOutlined />}
                    placeholder="Κωδικός Πρόσβασης"
                  />
                </Form.Item>
                <Form.Item
                  name="newPassword"
                  rules={[
                    {
                      required: true,
                      message: 'Καταχωρείστε κωδικό πρόσβασης!',
                    },
                  ]}
                >
                  <Input.Password
                    prefix={<LockOutlined />}
                    placeholder="Νέος Κωδικός Πρόσβασης"
                  />
                </Form.Item>
                <Form.Item
                  name="confirmPassword"
                  dependencies={['newPassword']}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: 'Επιβεβαιώστε τον κωδικό πρόσβασης!',
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('newPassword') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error('Οι κωδικοί δεν συμπίπτουν!'),
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    prefix={<LockOutlined />}
                    placeholder="Επιβεβαίωση Νέου Κωδικού"
                  />
                </Form.Item>
                <Flex gap="small" vertical>
                  <Row justify="center">
                    <Button htmlType="submit" size="large" style={resetButton}>
                      Αποθήκευση
                    </Button>
                  </Row>
                </Flex>
              </Form>
            </Col>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default Profile;
