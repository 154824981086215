import { FC } from 'react';
import { LandParcel } from '../../types/types';
import { dateEpochToGR } from '../../shared/services/helperFunctions';
import ActivitiesTable from './ActivitiesTable';
import endpoints from '../../shared/endPoints.json';
type Props = {
  selectedCultivation: LandParcel | undefined;
  activityAddition: boolean;
};
const CultivationCareTable: FC<Props> = ({
  selectedCultivation,
  activityAddition,
}) => {
  const columns = [
    {
      title: 'Καλλιεργητικές Φροντίδες',
      dataIndex: 'cultivationCare',
      key: 'cultivationCare',
    },
    {
      title: 'Hμερομηνία Έναρξης',
      dataIndex: 'startDate',
      key: 'startDate',
      render: (epochTime: string) => dateEpochToGR(+epochTime),
    },
    {
      title: 'Διάρκεια (Ημέρες)',
      dataIndex: 'duration',
      key: 'duration',
    },
    {
      title: 'Προσωπικό (Αριθμός)',
      dataIndex: 'personnel',
      key: 'personnel',
    },
  ];

  return (
    <ActivitiesTable
      activityPostEndpoint={endpoints.CALENDAR.LANDPARCELCULTIVATIONCARE}
      columns={columns}
      selectedCultivationId={selectedCultivation?.id}
      activityAddition={activityAddition}
    />
  );
};
export default CultivationCareTable;
