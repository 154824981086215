import {
  Button,
  Col,
  DatePicker,
  Form,
  FormProps,
  Input,
  Row,
  message,
} from 'antd';
import { FC, useEffect } from 'react';
import { baseFormLayout, fullWidth } from '../../../shared/styles/baseStyle';
import { LandParcel } from '../../../types/types';
import { useMutation } from '@tanstack/react-query';
import { mutateData } from '../../../shared/services/apiServices';
import useCreateAxiosInstance from '../../../shared/hooks/useCreateAxiosInstance';
import endPoints from '../../../shared/endPoints.json';
type Props = {
  selectedCultivation: LandParcel | undefined;
  setActivityAddition: React.Dispatch<React.SetStateAction<boolean>>;
  activityAddition: boolean;
};
const QualityCharacteristicsForm: FC<Props> = ({
  selectedCultivation,
  setActivityAddition,
  activityAddition,
}) => {
  const [messageApi, contextHolder] = message.useMessage();
  const { request } = useCreateAxiosInstance();
  const [form] = Form.useForm();
  const dateFormat = 'DD/MM/YYYY';
  const requiredRuleConfig = {
    required: true,
    message: 'Υποχρεωτικό πεδίο',
  };
  useEffect(() => {
    form.setFieldsValue({
      cultivation: selectedCultivation?.cultivation,
      landParcelId: selectedCultivation?.id,
    });
  }, [selectedCultivation]);

  const { mutate } = useMutation({
    mutationFn: (formValues) =>
      mutateData(
        request,
        endPoints.CALENDAR.LANDPARCELQUALITYCHARACTERISTIC,
        formValues,
        'post',
      ),
    onSuccess() {
      messageApi.success('Η ενέργεια προστέθηκε με επιτυχία!');
      form.resetFields([
        'measurementDate',
        'fruitSugars',
        'fruitHardness',
        'fruitColor',
        'expectedHarvestStartDate',
        'expectedHarvestEndDate',
        'allowableHarvestDate',
      ]);
      setActivityAddition(!activityAddition);
    },
  });
  const onFinish: FormProps['onFinish'] = async (values: any) => {
    const formattedValues = {
      ...values,
      measurementDate: values.measurementDate?.$d.getTime(),
      expectedHarvestStartDate: values.expectedHarvestStartDate?.$d.getTime(),
      expectedHarvestEndDate: values.expectedHarvestEndDate?.$d.getTime(),
      allowableHarvestDate: values.allowableHarvestDate?.$d.getTime(),
    };

    mutate(formattedValues);
  };
  return (
    <>
      {contextHolder}
      <Form
        {...baseFormLayout}
        layout="vertical"
        size="large"
        form={form}
        onFinish={onFinish}
      >
        <Row>
          <Col xs={12}>
            <Form.Item name="landParcelId" label="Κωδικός Αγροτεμαχίου">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item name="cultivation" label="Καλλιέργεια">
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Form.Item
              name="measurementDate"
              label="Ημ/νία μέτρησης"
              rules={[requiredRuleConfig]}
            >
              <DatePicker
                format={dateFormat}
                placeholder="Ημ/νία μέτρησης"
                style={fullWidth}
              />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item
              name="fruitSugars"
              label="Σάκχαρα καρπού"
              rules={[requiredRuleConfig]}
            >
              <Input placeholder="Σάκχαρα καρπού" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Form.Item
              name="fruitHardness"
              label="Σκληρότητα καρπού"
              rules={[requiredRuleConfig]}
            >
              <Input placeholder="Σκληρότητα καρπού" />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item
              name="fruitColor"
              label="Χρώμα καρπού"
              rules={[requiredRuleConfig]}
            >
              <Input placeholder="Χρώμα καρπού" />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="start">
          <Col xs={12}>
            <Form.Item
              name="expectedHarvestStartDate"
              label="Αναμενόμενη Ημ/νία έναρξης συγκομιδής"
              rules={[requiredRuleConfig]}
            >
              <DatePicker
                format={dateFormat}
                placeholder="Ημ/νία έναρξης συγκομιδής"
                style={fullWidth}
              />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item
              name="expectedHarvestEndDate"
              label="Αναμενόμενη Ημ/νία λήξης συγκομιδής"
              rules={[requiredRuleConfig]}
            >
              <DatePicker
                format={dateFormat}
                placeholder="Ημ/νία λήξης συγκομιδής"
                style={fullWidth}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="start">
          <Col xs={12}>
            <Form.Item
              name="allowableHarvestDate"
              label="Ημ/νία επιτρεπόμενης συγκομιδής"
              rules={[requiredRuleConfig]}
            >
              <DatePicker
                format={dateFormat}
                placeholder="Ημ/νία επιτρεπόμενης συγκομιδής"
                style={fullWidth}
              />
            </Form.Item>
          </Col>
          <Col xs={12}></Col>
        </Row>
        <Row justify="center">
          <Button htmlType="submit">Προσθήκη</Button>
        </Row>
      </Form>
    </>
  );
};
export default QualityCharacteristicsForm;
