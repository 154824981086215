import { Card, Col, Row } from 'antd';
import { FC, useState } from 'react';
import CalendarCultivations from '../../components/CalendarCultivations/CalendarCultivations';
import FertilizerForm from '../../components/Forms/Calendar/FertilizerForm';
import IrrigationForm from '../../components/Forms/Calendar/IrrigationForm';
import HarvestForm from '../../components/Forms/Calendar/HarvestForm';
import PlantProtectionForm from '../../components/Forms/Calendar/PlantProtectionForm';
import VisitsForm from '../../components/Forms/Calendar/VisitsForm';
import QualityCharacteristicsForm from '../../components/Forms/Calendar/QualityCharacteristicsForm';
import CultivationCareForm from '../../components/Forms/Calendar/CultivationCareForm';
import { marginRow } from '../../shared/styles/baseStyle';
import { LandParcel } from '../../types/types';
import FertilizerTable from '../../components/Tables/FertilizerTable';
import CultivationCareTable from '../../components/Tables/CultivationCareTable';
import IrrigationTable from '../../components/Tables/IrrigationTable';
import PlantProtectionTable from '../../components/Tables/PlantProtectionTable';
import VisitsTable from '../../components/Tables/VisitsTable';
import QualityCharacteristicsTable from '../../components/Tables/QualityCharacteristicsTable';
import HarvestTable from '../../components/Tables/HarvestTable';
import SelectActivity from '../../components/SelectActivity/SelectActivity';
const Calendar: FC = () => {
  const [selectedCultivation, setSelectedCultivation] = useState<LandParcel>();
  const [selectedActivityForForm, setSelectedActivityForForm] =
    useState<string>('');
  const [selectedActivityForTable, setSelectedActivityForTable] =
    useState<string>('');
  const [activityAdditionFertilizer, setActivityAdditionFertilizer] =
    useState<boolean>(false);
  const [activityAdditionCultivationCare, setActivityAdditionCultivationCare] =
    useState<boolean>(false);
  const [activityAdditionIrrigation, setActivityAdditionIrrigation] =
    useState<boolean>(false);
  const [activityAdditionHarvest, setActivityAdditionHarvest] =
    useState<boolean>(false);
  const [activityAdditionPlantProtection, setActivityAdditionPlantProtection] =
    useState<boolean>(false);
  const [activityAdditionVisit, setActivityAdditionVisit] =
    useState<boolean>(false);
  const [
    activityAdditionQualityCharacteristics,
    setActivityAdditionQualityCharacteristics,
  ] = useState<boolean>(false);

  const calendarActivityForms: { [key: string]: JSX.Element } = {
    Λίπανση: (
      <FertilizerForm
        selectedCultivation={selectedCultivation}
        setActivityAddition={setActivityAdditionFertilizer}
        activityAddition={activityAdditionFertilizer}
      />
    ),
    ΚαλλιεργητικέςΦροντίδες: (
      <CultivationCareForm
        selectedCultivation={selectedCultivation}
        setActivityAddition={setActivityAdditionCultivationCare}
        activityAddition={activityAdditionCultivationCare}
      />
    ),
    Άρδευση: (
      <IrrigationForm
        selectedCultivation={selectedCultivation}
        setActivityAddition={setActivityAdditionIrrigation}
        activityAddition={activityAdditionIrrigation}
      />
    ),
    Συγκομιδή: (
      <HarvestForm
        selectedCultivation={selectedCultivation}
        setActivityAddition={setActivityAdditionHarvest}
        activityAddition={activityAdditionHarvest}
      />
    ),
    Φυτοπροστασία: (
      <PlantProtectionForm
        selectedCultivation={selectedCultivation}
        setActivityAddition={setActivityAdditionPlantProtection}
        activityAddition={activityAdditionPlantProtection}
      />
    ),
    Επισκέψεις: (
      <VisitsForm
        selectedCultivation={selectedCultivation}
        setActivityAddition={setActivityAdditionVisit}
        activityAddition={activityAdditionVisit}
      />
    ),
    ΠοιοτικάΧαρακτηριστικά: (
      <QualityCharacteristicsForm
        selectedCultivation={selectedCultivation}
        setActivityAddition={setActivityAdditionQualityCharacteristics}
        activityAddition={activityAdditionQualityCharacteristics}
      />
    ),
  };
  const calendarActivityTables: { [key: string]: JSX.Element } = {
    Λίπανση: (
      <FertilizerTable
        selectedCultivation={selectedCultivation}
        activityAddition={activityAdditionFertilizer}
      />
    ),
    ΚαλλιεργητικέςΦροντίδες: (
      <CultivationCareTable
        selectedCultivation={selectedCultivation}
        activityAddition={activityAdditionCultivationCare}
      />
    ),
    Άρδευση: (
      <IrrigationTable
        selectedCultivation={selectedCultivation}
        activityAddition={activityAdditionIrrigation}
      />
    ),
    Φυτοπροστασία: (
      <PlantProtectionTable
        selectedCultivation={selectedCultivation}
        activityAddition={activityAdditionPlantProtection}
      />
    ),
    Επισκέψεις: (
      <VisitsTable
        selectedCultivation={selectedCultivation}
        activityAddition={activityAdditionVisit}
      />
    ),
    ΠοιοτικάΧαρακτηριστικά: (
      <QualityCharacteristicsTable
        selectedCultivation={selectedCultivation}
        activityAddition={activityAdditionQualityCharacteristics}
      />
    ),
    Συγκομιδή: (
      <HarvestTable
        selectedCultivation={selectedCultivation}
        activityAddition={activityAdditionHarvest}
      />
    ),
  };

  return (
    <Col>
      <Row justify="center" style={marginRow}>
        <Col xs={23} sm={23} md={23} lg={12} xl={11} xxl={12}>
          <Card title="Αγροτεμάχια">
            <CalendarCultivations
              selectedCultivation={selectedCultivation}
              setSelectedCultivation={setSelectedCultivation}
            />
          </Card>
        </Col>
        <Col xs={23} sm={23} md={23} lg={12} xl={12} xxl={10}>
          <Card title="Προσθήκη Δραστηριότητας/Ενέργειας">
            <SelectActivity
              selectedCultivation={selectedCultivation}
              setSelectedActivity={setSelectedActivityForForm}
            >
              {selectedActivityForForm &&
                calendarActivityForms[
                  selectedActivityForForm.replace(/\s+/g, '')
                ]}
            </SelectActivity>
          </Card>
        </Col>
      </Row>
      <Row justify="center">
        <Col xs={23} sm={23} md={23} lg={24} xl={23} xxl={22}>
          <Card title="Ημερολόγιο Αγρού">
            <SelectActivity
              selectedCultivation={selectedCultivation}
              setSelectedActivity={setSelectedActivityForTable}
            >
              {selectedActivityForTable &&
                calendarActivityTables[
                  selectedActivityForTable.replace(/\s+/g, '')
                ]}
            </SelectActivity>
          </Card>
        </Col>
      </Row>
    </Col>
  );
};
export default Calendar;
