import { FC } from 'react';
import NutrientsBalanceTable from './NutrientsBalanceTable';
import { Col, Divider, Row } from 'antd';
import { OutputBalance } from '../../types/types';
type Props = {
  balanceOutput: OutputBalance | null;
};
const NutrientsBalanceOutputTable: FC<Props> = ({ balanceOutput }) => {
  if (!balanceOutput) return <></>;
  const transformOutput = (inputData: any) => {
    return [
      {
        row: 'Έκπλυση',
        n: inputData.nleaching,
        key: '1',
      },
      {
        row: 'Προσληψη',
        n: inputData.uptake.n,
        p: inputData.uptake.p,
        k: inputData.uptake.k,
        p2O5: inputData.uptake.p2O5,
        k2O: inputData.uptake.k2O,
        key: '2',
      },
      {
        row: 'Τελική κατάσταση εδάφους',
        n: inputData.nminPostharvest,
        key: '3',
      },
      {
        row: 'Aπονιτροποίηση ',
        n: inputData.ndenitrification,
        key: '4',
      },
      {
        row: 'Εξαέρωση',
        n: inputData.nvolatilization,
        key: '5',
      },
    ];
  };
  return (
    <>
      <Divider />
      <Row>
        <Col>
          <NutrientsBalanceTable
            balance={transformOutput(balanceOutput)}
            summaryDesciption={'ΣΥΝΟΛΟ ΕΚΡΟΩΝ'}
          />
        </Col>
      </Row>
    </>
  );
};
export default NutrientsBalanceOutputTable;
