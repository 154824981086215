import { FC } from 'react';
import { LandParcel } from '../../types/types';
import ActivitiesTable from './ActivitiesTable';
import { dateEpochToGR } from '../../shared/services/helperFunctions';
import endpoints from '../../shared/endPoints.json';
type Props = {
  selectedCultivation: LandParcel | undefined;
  activityAddition: boolean;
};
const FertilizerTable: FC<Props> = ({
  selectedCultivation,
  activityAddition,
}) => {
  const columns = [
    {
      title: 'Ημ/νια Εφαρμογής',
      dataIndex: 'applicationDate',
      key: 'applicationDate',

      render: (epochTime: string) => dateEpochToGR(+epochTime),
    },
    {
      title: 'Καιρός κατά την Εφαρμογή',
      dataIndex: 'fertilizerWeather',
      key: 'fertilizerWeather',
    },
    {
      title: 'Τύπος Λιπάσματος',
      dataIndex: 'fertilizerType',
      key: 'fertilizerType',
    },
    {
      title: 'Δόση Λίπανσης (kg/ha ή lt/ha)',
      dataIndex: 'fertilizerDose',
      key: 'fertilizerDose',
    },
    {
      title: 'Τρόπος Εφαρμογής',
      dataIndex: 'fertilizerMode',
      key: 'fertilizerMode',
    },
    {
      title: 'Μέθοδος Εφαρμογής',
      dataIndex: 'fertilizerApplicationMethod',
      key: 'fertilizerApplicationMethod',
    },
    {
      title: 'Συχνότητα Εφαρμογής',
      dataIndex: 'fertilizerApplicationFrequency',
      key: 'fertilizerApplicationFrequency',
    },
  ];

  return (
    <ActivitiesTable
      activityPostEndpoint={endpoints.CALENDAR.LANDPARCELFERTILIZER}
      columns={columns}
      selectedCultivationId={selectedCultivation?.id}
      activityAddition={activityAddition}
    />
  );
};
export default FertilizerTable;
