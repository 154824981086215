import { FC } from 'react';
import { dateEpochToGR } from '../../shared/services/helperFunctions';
import ActivitiesTable from './ActivitiesTable';
import { LandParcel } from '../../types/types';
import endpoints from '../../shared/endPoints.json';
type Props = {
  selectedCultivation: LandParcel | undefined;
  activityAddition: boolean;
};
const HarvestTable: FC<Props> = ({ selectedCultivation, activityAddition }) => {
  const columns = [
    {
      title: 'Hμερομηνία Προεκτίμησης',
      dataIndex: 'preEstimatedDate',
      key: 'preEstimatedDate',
      render: (epochTime: string) => dateEpochToGR(+epochTime),
    },
    {
      title: 'Προεκτίμηση ανά Εκτάριο (kg/ha)',
      dataIndex: 'preEstimatePerHectare',
      key: 'preEstimatePerHectare',
    },
    {
      title: 'Προεκτίμηση Ολική (kg)',
      dataIndex: 'preEstimateTotal',
      key: 'preEstimateTotal',
    },
    {
      title: 'Ημερομηνία Συγκομιδής',
      dataIndex: 'harvestDate',
      key: 'harvestDate',
      render: (epochTime: string) => dateEpochToGR(+epochTime),
    },
    {
      title: 'Τελική Παραδοτέα Ποσότητα (kg)',
      dataIndex: 'finalDeliverableQuantity',
      key: 'finalDeliverableQuantity',
    },
    {
      title: 'Διαφορά (%)',
      dataIndex: 'difference',
      key: 'difference',
    },
    {
      title: 'Τρόπος Συγκομιδής',
      dataIndex: 'harvestMethod',
      key: 'harvestMethod',
    },
  ];

  return (
    <ActivitiesTable
      activityPostEndpoint={endpoints.CALENDAR.LANDPARCELHARVEST}
      columns={columns}
      selectedCultivationId={selectedCultivation?.id}
      activityAddition={activityAddition}
    />
  );
};
export default HarvestTable;
