import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  FormProps,
  Input,
  Row,
  Select,
  message,
} from 'antd';
import { FC } from 'react';
import { baseStyle, baseFormLayout } from '../../shared/styles/baseStyle';
import { useQuery, useMutation } from '@tanstack/react-query';
import { getData, mutateData } from '../../shared/services/apiServices';
import useCreateAxiosInstance from '../../shared/hooks/useCreateAxiosInstance';
import endPoints from '../../shared/endPoints.json';
import { NamedItem } from '../../types/types';

const AddUser: FC = () => {
  const { request } = useCreateAxiosInstance();
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const dateFormat = 'DD/MM/YYYY';
  const { Option } = Select;
  const requiredRuleConfig = {
    required: true,
    message: 'Υποχρεωτικό πεδίο',
  };

  const { mutate } = useMutation({
    mutationFn: (formValues) =>
      mutateData(request, endPoints.USER.REGISTERADMIN, formValues, 'post'),
    onSuccess() {
      messageApi.success('Ο χρήστης δημιουργήθηκε με επιτυχία!');
      form.resetFields();
    },
  });

  const { data: userOrigin } = useQuery({
    queryKey: ['userOrigin'],
    queryFn: () => getData(request, endPoints.USER.USERORIGIN),
    select(data) {
      return data.data.map((item: NamedItem) => ({
        value: item.id,
        label: item.name,
      }));
    },
  });

  const { data: subscriptionPackage } = useQuery({
    queryKey: ['subscriptionPackage'],
    queryFn: () => getData(request, endPoints.USER.SUBSCRIPTIONPACKAGE),
    select(data) {
      return data.data.map((item: NamedItem) => ({
        value: item.id,
        label: item.name,
      }));
    },
  });
  const mobilePrefix = (
    <Form.Item name="prefix" noStyle>
      <Select style={{ width: 75 }} defaultValue={'30'}>
        <Option value="30">+30</Option>
      </Select>
    </Form.Item>
  );

  const onFinish: FormProps['onFinish'] = async (values) => {
    mutate({
      ...values,
      subscriptionDateStarted: values.subscriptionDateStarted?.$d.getTime(),
      vatNumber: parseInt(values?.vatNumber),
    });
  };

  return (
    <>
      {contextHolder}
      <Row align="middle" justify="center" style={{ ...baseStyle.mainRow }}>
        <Card title={'Δημιουργία Χρήστη'} style={{ width: '95%' }}>
          <Form
            {...baseFormLayout}
            name="basic"
            layout="vertical"
            size="middle"
            form={form}
            initialValues={{ role: 'Καλλιεργητής' }}
            onFinish={onFinish}
          >
            <Row>
              <Col xs={12} sm={12} lg={8} xl={6}>
                <Form.Item
                  name="lastName"
                  label="Επίθετο"
                  rules={[requiredRuleConfig]}
                >
                  <Input placeholder="Επίθετο" />
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} lg={8} xl={6}>
                <Form.Item
                  name="firstName"
                  label="Όνομα"
                  rules={[requiredRuleConfig]}
                >
                  <Input placeholder="Όνομα" />
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} lg={8} xl={6}>
                <Form.Item
                  name="email"
                  label="Username (e-mail)"
                  rules={[
                    {
                      required: true,
                      type: 'email',
                      message: 'Εισάγετε έγκυρο email',
                    },
                  ]}
                >
                  <Input placeholder="Username (e-mail)" />
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} lg={8} xl={6}>
                <Form.Item
                  name="vatNumber"
                  label="ΑΦΜ"
                  rules={[
                    requiredRuleConfig,
                    {
                      pattern: /^[0-9]{9}$/,
                      message: 'Το ΑΦΜ πρέπει να είναι 9 ψηφία',
                    },
                  ]}
                >
                  <Input placeholder="ΑΦΜ" />
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} lg={8} xl={6}>
                <Form.Item
                  name="phone"
                  label="Κινητό Τηλέφωνο"
                  rules={[requiredRuleConfig]}
                >
                  <Input
                    addonBefore={mobilePrefix}
                    placeholder="Κινητό Τηλέφωνο"
                  />
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} lg={8} xl={6}>
                <Form.Item name="role" label="Ρόλος">
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} lg={8} xl={6}>
                <Form.Item name="addressName" label="Οδός">
                  <Input placeholder="Οδός" />
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} lg={8} xl={6}>
                <Form.Item name="addressNumber" label="Αριθμός">
                  <Input placeholder="Αριθμός" />
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} lg={8} xl={6}>
                <Form.Item name="district" label="Περιοχή">
                  <Input placeholder="Περιοχή" />
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} lg={8} xl={6}>
                <Form.Item name="municipality" label="Δήμος">
                  <Input placeholder="Δήμος" />
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} lg={8} xl={6}>
                <Form.Item name="postalCode" label="Τ.Κ">
                  <Input placeholder="Ταχυδρομικός Κώδικας" />
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} lg={8} xl={6}>
                <Form.Item
                  name="subscriptionPackageId"
                  label="Επιλογή Πακέτου"
                  rules={[requiredRuleConfig]}
                >
                  <Select
                    options={subscriptionPackage}
                    allowClear
                    placeholder="Επιλογή Πακέτου"
                  />
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} lg={8} xl={6}>
                <Form.Item
                  name="userOriginId"
                  label="Προέλευση"
                  rules={[requiredRuleConfig]}
                >
                  <Select
                    options={userOrigin}
                    allowClear
                    placeholder="Προέλευση"
                  />
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} lg={8} xl={6}>
                <Form.Item
                  name="subscriptionDateStarted"
                  label="Έναρξη Συνδρομής"
                  rules={[requiredRuleConfig]}
                >
                  <DatePicker
                    format={dateFormat}
                    placeholder="Έναρξη Συνδρομής"
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={24} lg={16} xxl={12}>
                <Form.Item name="notes" label="Σημειώσεις/Παρατηρήσεις">
                  <Input.TextArea
                    rows={4}
                    placeholder="Σημειώσεις/Παρατηρήσεις"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row justify="center">
              <Button htmlType="submit">Αποθήκευση</Button>
            </Row>
          </Form>
        </Card>
      </Row>
    </>
  );
};
export default AddUser;
