/* eslint-disable react/prop-types */
import { useEffect } from 'react';
import { MapContainer, TileLayer, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import DrawTools from './DrawTools';

const MapView = ({
  height,
  coordinates,
  setPolygon,
  showDrawControls,
  landParcelMutation,
  clearCoordinates,
}) => {
  const parsedCoords = coordinates && JSON.parse(coordinates);
  const center = parsedCoords ? parsedCoords[0] : [39.5, 22.0];
  const zoomLevel = coordinates ? 14 : 9;
  const maxZoom = 20;
  const minZoom = 2;

  const ComponentResize = () => {
    const map = useMap();
    useEffect(() => {
      if (map) {
        map.invalidateSize();
      }
    }, [map]);

    return null;
  };
  const FlyToPosition = () => {
    const map = useMap();
    useEffect(() => {
      if (map && coordinates) {
        map.flyTo([center[0], center[1]], 14);
        setTimeout(() => {
          window.dispatchEvent(new Event('resize'));
        }, 2500);
      }
    }, []);

    return null;
  };
  return (
    <MapContainer
      attributionControl={false}
      center={[center[0], center[1]]}
      zoom={zoomLevel}
      maxZoom={maxZoom}
      minZoom={minZoom}
      scrollWheelZoom={true}
      style={{
        height: height,
      }}
    >
      <ComponentResize />
      <FlyToPosition />
      <DrawTools
        coordinates={parsedCoords}
        setPolygon={setPolygon}
        showDrawControls={showDrawControls}
        landParcelMutation={landParcelMutation}
        clearCoordinates={clearCoordinates}
      />
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
      />
    </MapContainer>
  );
};

export default MapView;
