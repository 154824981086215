import { FC } from 'react';
import NutrientsBalanceTable from './NutrientsBalanceTable';
import { Col, Divider, Row } from 'antd';
import { InputBalance } from '../../types/types';
type Props = {
  balanceInput: InputBalance | null;
};
const NutrientsBalanceInputTable: FC<Props> = ({ balanceInput }) => {
  if (!balanceInput) return <></>;
  const transformInput = (inputData: any) => {
    return [
      {
        row: 'Εφαρμογή λίπανσης',
        n: inputData.appliedFertilizer.n,
        p: inputData.appliedFertilizer.p,
        k: inputData.appliedFertilizer.k,
        p2O5: inputData.appliedFertilizer.p2O5,
        k2O: inputData.appliedFertilizer.k2O,
        key: '1',
      },
      {
        row: 'Δέσμευση',
        n: inputData.nfixation,
        key: '2',
      },
      {
        row: 'Αρχική κατάσταση εδάφους',
        n: inputData.nminInitial,
        key: '3',
      },
      {
        row: 'Άρδευση',
        n: inputData.nwater,
        key: '4',
      },
      {
        row: 'Ανοργανοποίηση',
        n: inputData.nmineralization,
        key: '5',
      },
      {
        row: 'Σύσταση λίπανσης',
        n: inputData.recommendedFertilizer.n,
        p: inputData.recommendedFertilizer.p,
        k: inputData.recommendedFertilizer.k,
        p2O5: inputData.recommendedFertilizer.p2O5,
        k2O: inputData.recommendedFertilizer.k2O,
        key: '6',
      },
    ];
  };
  return (
    <>
      <Divider type="horizontal" plain>
        Ισοζύγιο θρεπτικών συστατικών
      </Divider>
      <Row>
        <Col>
          <NutrientsBalanceTable
            balance={transformInput(balanceInput)}
            summaryDesciption={'ΣΥΝΟΛΟ ΕΙΣΡΟΩΝ'}
          />
        </Col>
      </Row>
    </>
  );
};
export default NutrientsBalanceInputTable;
