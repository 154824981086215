import {
  Form,
  Row,
  Button,
  Col,
  Select,
  InputNumber,
  FormProps,
  Divider,
} from 'antd';
import { baseFormLayout, fullWidth } from '../../../shared/styles/baseStyle';
import { FC } from 'react';
import { useMutation } from '@tanstack/react-query';
import { mutateData } from '../../../shared/services/apiServices';
import useCreateAxiosInstance from '../../../shared/hooks/useCreateAxiosInstance';
import endPoints from '../../../shared/endPoints.json';

type Props = {
  landParcelId: number | undefined;
  setG3MutationSettled: React.Dispatch<React.SetStateAction<boolean>>;
};
const G3Form: FC<Props> = ({ landParcelId, setG3MutationSettled }) => {
  const { request } = useCreateAxiosInstance();

  const [form] = Form.useForm();
  const requiredRuleConfig = {
    required: true,
    message: 'Υποχρεωτικό πεδίο',
  };
  const mutationKey = ['g3'];
  const { mutate } = useMutation({
    mutationFn: (formValues) =>
      mutateData(
        request,
        endPoints.NUTRIENTS.G3CALCULATION,
        formValues,
        'post',
      ),
    mutationKey,
    onSuccess() {
      form.resetFields();
    },
    onSettled() {
      setG3MutationSettled(true);
    },
  });

  const onFinish: FormProps['onFinish'] = async (values: any) => {
    const formattedValues = {
      ...values,
      landParcelId: landParcelId,
      seed: values.seed.toString(),
      consumption: values.consumption.toString(),
    };
    mutate(formattedValues);
  };

  return (
    <>
      <Divider type="horizontal" plain>
        Εκτίμηση Ανθρακικού αποτυπώματος
      </Divider>
      <Form
        {...baseFormLayout}
        layout="vertical"
        size="middle"
        form={form}
        onFinish={onFinish}
        initialValues={{ combustible: 'Diesel' }}
      >
        <Row>
          <Col xs={12} lg={6}>
            <Form.Item
              name="combustible"
              label="Καύσιμο"
              rules={[requiredRuleConfig]}
            >
              <Select
                allowClear
                options={[
                  { value: 'Diesel', label: 'Πετρέλαιο' },
                  { value: 'Gasoline', label: 'Βενζίνη' },
                ]}
              />
            </Form.Item>
          </Col>
          <Col xs={12} lg={6}>
            <Form.Item
              name="residues"
              label="Υπολείμματα"
              rules={[requiredRuleConfig]}
            >
              <Select
                allowClear
                options={[
                  { value: 'Removed', label: 'Αφαίρεση' },
                  { value: 'Incorporation', label: 'Ενσωμάτωση' },
                ]}
                placeholder="Υπολείμματα"
              />
            </Form.Item>
          </Col>
          <Col xs={12} lg={6}>
            <Form.Item
              name="consumption"
              label="Κατανάλωση (lt/ha)"
              rules={[requiredRuleConfig]}
            >
              <InputNumber
                type="number"
                style={fullWidth}
                step={0.1}
                min={0}
                placeholder="Κατανάλωση (lt/ha)"
              />
            </Form.Item>
          </Col>
          <Col xs={12} lg={6}>
            <Form.Item
              name="seed"
              label="Σπόροι : ποσότητα (kg/ha)"
              rules={[requiredRuleConfig]}
            >
              <InputNumber
                type="number"
                style={fullWidth}
                step={0.1}
                min={0}
                placeholder="Σπόροι : ποσότητα (kg/ha)"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="center">
          <Button htmlType="submit">Υπολογισμός</Button>
        </Row>
      </Form>
    </>
  );
};
export default G3Form;
