import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from 'react-oidc-context';
import AuthContextProvider from './shared/context/auth-context';
import { ConfigProvider } from 'antd';
import elGR from 'antd/locale/el_GR';
import { config } from './shared/styles/theme/config';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: 0,
      staleTime: 0,
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

/*const oidcConfig = {
  authority: 'https://accounts.google.com', // Google's OIDC authority
  client_id:
    '210959220155-ekpv7sqjvc04scj3ugjdlj5k8djbnpac.apps.googleusercontent.com', // Your Google OAuth client ID
  redirect_uri: 'http://localhost:3000/ssologin', // Your registered redirect URI
  client_secret: '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',
  response_type: 'code', // Authorization Code Flow
  scope: 'openid email profile',
  // post_logout_redirect_uri: 'http://localhost:3000/', // Where the user is redirected after logout
  //response_type: 'code', // Using Authorization Code Flow with PKCE
  //scope: 'openid email profile', // Scopes to request,
  //automaticSilentRenew: true, // Optional: Automatically renew tokens when they expire
  //response_mode: 'query', // This could be 'query' or 'fragment' based on your setup
};*/

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <QueryClientProvider client={queryClient}>
    <AuthContextProvider>
      <ConfigProvider theme={config} locale={elGR}>
        <App />
      </ConfigProvider>
    </AuthContextProvider>
  </QueryClientProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
