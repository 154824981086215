import { Col, Divider, Row, Select, Typography } from 'antd';
import { FC } from 'react';
import { fullWidth } from '../../shared/styles/baseStyle';
import { NamedItem, LandParcel } from '../../types/types';
import { marginRow } from '../../shared/styles/baseStyle';
import useCreateAxiosInstance from '../../shared/hooks/useCreateAxiosInstance';
import { useQuery } from '@tanstack/react-query';
import { getData } from '../../shared/services/apiServices';
import endPoints from '../../shared/endPoints.json';

type Props = {
  selectedCultivation: LandParcel | undefined;
  setSelectedActivity: React.Dispatch<React.SetStateAction<string>>;
  children: any;
};
const SelectActivity: FC<Props> = ({
  selectedCultivation,
  setSelectedActivity,
  children,
}) => {
  const { request } = useCreateAxiosInstance();
  const { data: calendarActivityItems, isLoading } = useQuery({
    queryKey: ['calendarActivityItems'],
    queryFn: () => getData(request, endPoints.CALENDAR.CALENDARACTIVITY),
    select(data) {
      return data.data.map((item: NamedItem) => ({
        value: item.id,
        label: item.name,
      }));
    },
  });
  return (
    <>
      {selectedCultivation ? (
        <>
          <Row style={marginRow}>
            <Typography.Text strong>Ενέργεια</Typography.Text>
          </Row>
          <Row>
            <Col span={13}>
              <Select
                size="large"
                style={fullWidth}
                allowClear
                options={calendarActivityItems}
                placeholder="Επιλέξτε Ενέργεια"
                onSelect={(value: any, option: any) => {
                  setSelectedActivity(option.label);
                }}
                onClear={() => setSelectedActivity('')}
              />
            </Col>
          </Row>
          <Divider />
          <Row style={marginRow}>
            <Typography.Text>Επιλεγμένο αγροτεμάχιο:</Typography.Text>
            <Typography.Text strong>
              &nbsp;{selectedCultivation?.name}
            </Typography.Text>
          </Row>
          {children}
        </>
      ) : (
        <Row style={marginRow}>
          <Typography.Text strong>
            Επιλέξτε πρώτα αγροτεμάχιο από τη λίστα αγροτεμαχίων
          </Typography.Text>
        </Row>
      )}
    </>
  );
};
export default SelectActivity;
