export const baseStyle = {
  layoutStyle: { minHeight: '100vh' },
  mainRow: { minHeight: '100%' },
};
export const baseColors = {
  background: '#F0F0F0',
  fsGreen: '#67a339',
  fsDeepGreen: '#497527',
  fsGrey: '#474747',
  fsLightGrey: '#c5c5c1',
};
export const baseFormLayout = {
  labelCol: {
    xs: { span: 24 },
    md: { span: 20 },
    lg: { span: 22 },
  },
  wrapperCol: {
    xs: { span: 22 },
    md: { span: 20 },
    lg: { span: 22 },
  },
};

export const fullWidth = { width: '100%' };
export const marginRow = { marginBottom: 5, marginTop: 5 };
export const outerRow = {
  minHeight: '100vh',
  backgroundColor: baseColors.background,
};
