import { Navigate, createBrowserRouter } from 'react-router-dom';
import Home from '../../pages/Home';
import AddUser from '../../pages/Users/AddUser';
import UsersCatalogue from '../../pages/Users/UsersCatalogue';
import AddCultivation from '../../pages/Cultivations/AddCultivation';
import CultivationsCatalogue from '../../pages/Cultivations/CultivationCatalogue';
import Calendar from '../../pages/Calendar/Calendar';
import Login from '../../pages/Login/Login';
import Mainlayout from '../MainLayout';
import PrivateRoute from './PrivateRoute';
import Register from '../../pages/Register/Register';
import GlobalRoute from './GlobalRouter';
import PrivateForRole from './PrivateForRole';
import Profile from '../../pages/Profile/Profile';
import Callback from '../../Callback';

export const router = createBrowserRouter([
  {
    path: '/login',
    element: <GlobalRoute />,
    children: [{ path: '', element: <Login /> }],
  },
  {
    path: '/ssologin',
    element: <GlobalRoute />,
    children: [{ path: '', element: <Callback /> }],
  },
  {
    path: '/register',
    element: <GlobalRoute />,
    children: [{ path: '', element: <Register /> }],
  },
  {
    path: '/resetPassword',
    element: <GlobalRoute />,
    children: [{ path: '', element: <Profile /> }],
  },
  {
    path: '/',
    element: <PrivateRoute />,
    children: [
      {
        path: '/',
        element: <Mainlayout />,
        children: [
          { path: '', element: <Home /> },
          {
            path: 'users/catalogue',
            element: <PrivateForRole />,
            children: [{ path: '', element: <UsersCatalogue /> }],
          },
          {
            path: 'users/add',
            element: <PrivateForRole />,
            children: [{ path: '', element: <AddUser /> }],
          },
          {
            path: 'cultivations/add',
            element: <AddCultivation />,
          },
          {
            path: 'cultivations/catalogue',
            element: <CultivationsCatalogue />,
          },
          {
            path: 'calendar',
            element: <Calendar />,
          },
          {
            path: 'profile',
            element: <Profile />,
          },
        ],
      },
      { path: '*', element: <Navigate to="/" replace /> },
    ],
  },
]);
