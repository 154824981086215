import { FC } from 'react';
import { dateEpochToGR } from '../../shared/services/helperFunctions';
import ActivitiesTable from './ActivitiesTable';
import { LandParcel } from '../../types/types';
import endpoints from '../../shared/endPoints.json';
type Props = {
  selectedCultivation: LandParcel | undefined;
  activityAddition: boolean;
};
const IrrigationTable: FC<Props> = ({
  selectedCultivation,
  activityAddition,
}) => {
  const columns = [
    {
      title: 'Μέθοδος Άρδευσης',
      dataIndex: 'irrigationMethod',
      key: 'irrigationMethod',
    },
    {
      title: 'Hμερομηνία Έναρξης',
      dataIndex: 'startDate',
      key: 'startDate',
      render: (epochTime: string) => dateEpochToGR(+epochTime),
    },
    {
      title: 'Διάρκεια (Ώρες)',
      dataIndex: 'duration',
      key: 'duration',
    },
    {
      title: 'Ποσότητα / εκτάριο m³',
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: 'Κυβικά / ώρα (m³ γεώτρησης)',
      dataIndex: 'cubic',
      key: 'cubic',
    },
  ];

  return (
    <ActivitiesTable
      activityPostEndpoint={endpoints.CALENDAR.LANDPARCELIRRIGATION}
      columns={columns}
      selectedCultivationId={selectedCultivation?.id}
      activityAddition={activityAddition}
    />
  );
};
export default IrrigationTable;
