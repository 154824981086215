import { FC } from 'react';
import { dateEpochToGR } from '../../shared/services/helperFunctions';
import ActivitiesTable from './ActivitiesTable';
import { LandParcel } from '../../types/types';
import endpoints from '../../shared/endPoints.json';
type Props = {
  selectedCultivation: LandParcel | undefined;
  activityAddition: boolean;
};
const PlantProtectionTable: FC<Props> = ({
  selectedCultivation,
  activityAddition,
}) => {
  const columns = [
    {
      title: 'Ημερομηνία',
      dataIndex: 'plantProtectionDate',
      key: 'plantProtectionDate',
      render: (epochTime: string) => dateEpochToGR(+epochTime),
    },
    {
      title: 'Καιρός κατά την Εφαρμογή',
      dataIndex: 'weather',
      key: 'weather',
    },
    {
      title: 'Δραστική ουσία',
      dataIndex: 'activeSubstance',
      key: 'activeSubstance',
    },
    {
      title: 'Εμπορικό σκεύασμα',
      dataIndex: 'commercialFormulation',
      key: 'commercialFormulation',
    },
    {
      title: 'Δόση εφαρμογής',
      dataIndex: 'applicationDose',
      key: 'applicationDose',
    },
    {
      title: 'Μονάδα Δόσης',
      dataIndex: 'doseUnit',
      key: 'doseUnit',
    },
    {
      title: 'Όρια Ασφάλειας (PHI)',
      dataIndex: 'perimeterOfSafety',
      key: 'perimeterOfSafety',
    },
    {
      title: 'Όγκος Ψεκαστικού Διαλύματος (lt/ha)',
      dataIndex: 'volumeOfSpraySolution',
      key: 'volumeOfSpraySolution',
    },
    {
      title: 'Δυνατότητα Συγκομιδής',
      dataIndex: 'harvestAbility',
      key: 'harvestAbility',
    },
    {
      title: 'Σημειώσεις/Παρατηρήσεις',
      dataIndex: 'observations',
      key: 'observations',
    },
  ];

  return (
    <ActivitiesTable
      activityPostEndpoint={endpoints.CALENDAR.LANDPARCELPLANTPROTECTION}
      columns={columns}
      selectedCultivationId={selectedCultivation?.id}
      activityAddition={activityAddition}
    />
  );
};
export default PlantProtectionTable;
