import { Table } from 'antd';
import { FC } from 'react';
type Props = {
  dataSource: any;
  columns: any;
  loading: boolean;
  paginationConfig: any;
  onChange: any;
  rowSelection: any;
};
const BaseTable: FC<Props> = ({
  dataSource,
  columns,
  loading,
  paginationConfig,
  onChange,
  rowSelection,
}) => {
  const uniqueDataSource = dataSource?.map((data: any, index: any) => ({
    ...data,
    key: data.id ? `${data.id}` : `${index}`,
  }));
  return (
    <Table
      dataSource={uniqueDataSource}
      columns={columns}
      pagination={paginationConfig}
      loading={loading}
      style={{ overflowX: 'auto' }}
      onChange={onChange}
      rowSelection={rowSelection}
    />
  );
};
export default BaseTable;
