import { FC, useEffect } from 'react';
import { Button } from 'antd';
import { useQuery } from '@tanstack/react-query';
import { getData } from '../../shared/services/apiServices';
import useCreateAxiosInstance from '../../shared/hooks/useCreateAxiosInstance';
import endPoints from '../../shared/endPoints.json';
type Props = {
  selectedCultivationId: number | undefined;
  pdfFileName: string | null;
};
const DownloadFile: FC<Props> = ({ selectedCultivationId, pdfFileName }) => {
  const { request } = useCreateAxiosInstance();

  const basePdfUrl = `${endPoints.CULTIVATION.LANDPARCEL}/${selectedCultivationId}/${endPoints.CULTIVATION.PDF}`;
  const { data, refetch } = useQuery({
    queryKey: ['pdf'],
    queryFn: () => getData(request, basePdfUrl, 'blob'),
    select(data) {
      return data.data;
    },
    enabled: false,
  });
  useEffect(() => {
    if (data) {
      const pdfBlob = new Blob([data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(pdfBlob);
      const tempLink = document.createElement('a');
      tempLink.href = url;
      tempLink.setAttribute('download', `${pdfFileName}`);
      document.body.appendChild(tempLink);
      tempLink.click();
      document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(url);
    }
  }, [data]);
  return (
    <Button
      type="link"
      onClick={() => {
        refetch();
      }}
    >
      {pdfFileName}
    </Button>
  );
};

export default DownloadFile;
