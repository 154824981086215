import { jwtDecode, JwtPayload } from 'jwt-decode';
import { FC, createContext, useCallback, useMemo, useState } from 'react';

type Props = {
  children: any;
};
interface ExtendedJwtPayload extends JwtPayload {
  role: string;
  unique_name: string;
  UserId: string;
}
interface IAuthContext {
  uniqueName: string | null;
  role: string | null;
  userId: string | null;
  token: string | null;
  refreshToken: string | null;
  login: (tokens: any) => void;
  logout: () => void;
}

export const AuthContext = createContext<IAuthContext>({
  uniqueName: '',
  role: '',
  userId: '',
  token: '',
  refreshToken: '',
  login: () => {
    // intentionally empty
  },
  logout: () => {
    // intentionally empty
  },
});

const AuthContextProvider: FC<Props> = ({ children }) => {
  const [role, setRole] = useState(localStorage.getItem('role'));
  const [userId, setUserId] = useState(localStorage.getItem('userId'));
  const [token, setToken] = useState(localStorage.getItem('accessToken'));
  const [refreshToken, setRefreshToken] = useState(
    localStorage.getItem('refreshToken'),
  );
  const [uniqueName, setUniqueName] = useState(
    localStorage.getItem('uniqueName'),
  );

  const login = useCallback((tokens: any) => {
    const decodedItem = jwtDecode<ExtendedJwtPayload>(tokens.accessToken);
    localStorage.setItem('accessToken', tokens.accessToken);
    localStorage.setItem('refreshToken', tokens.refreshToken);
    localStorage.setItem('role', decodedItem.role);
    localStorage.setItem('userId', decodedItem.UserId);
    localStorage.setItem('uniqueName', decodedItem.unique_name);
    setToken(tokens.accessToken);
    setRefreshToken(tokens.refreshToken);
    setRole(decodedItem.role);
    setUniqueName(decodedItem.unique_name);
    setUserId(decodedItem.UserId);
  }, []);

  const logout = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('role');
    localStorage.removeItem('userId');
    localStorage.removeItem('uniqueName');
    setToken(null);
    setRefreshToken(null);
    setRole(null);
    setUniqueName(null);
    setUserId(null);
  };

  const memoedValue = useMemo(
    () => ({
      uniqueName,
      role,
      token,
      refreshToken,
      userId,
      login,
      logout,
    }),
    [uniqueName, role, token, refreshToken, userId, login, logout],
  );

  return (
    <AuthContext.Provider value={memoedValue}>{children}</AuthContext.Provider>
  );
};
export default AuthContextProvider;
