import { FC } from 'react';
import { dateEpochToGR } from '../../shared/services/helperFunctions';
import ActivitiesTable from './ActivitiesTable';
import { LandParcel } from '../../types/types';
import endpoints from '../../shared/endPoints.json';
type Props = {
  selectedCultivation: LandParcel | undefined;
  activityAddition: boolean;
};
const VisitsTable: FC<Props> = ({ selectedCultivation, activityAddition }) => {
  const columns = [
    {
      title: 'Hμερομηνία Επισκέψης',
      dataIndex: 'visitDate',
      key: 'visitDate',
      render: (epochTime: string) => dateEpochToGR(+epochTime),
    },
    {
      title: 'Λόγος Επίσκεψης',
      dataIndex: 'visitReason',
      key: 'visitReason',
    },
    {
      title: 'Παρατηρήσεις - Συστάσεις',
      dataIndex: 'observations',
      key: 'observations',
    },
  ];

  return (
    <ActivitiesTable
      activityPostEndpoint={endpoints.CALENDAR.LANDPARCELVISIT}
      columns={columns}
      selectedCultivationId={selectedCultivation?.id}
      activityAddition={activityAddition}
    />
  );
};
export default VisitsTable;
