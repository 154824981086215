import { Request } from '../../types/types';
import { Method } from 'axios';

export const mutateData = async (
  request: Request,
  url: string,
  data: any,
  apiMethod: Method,
) => {
  return await request({
    url: url,
    method: apiMethod,
    data: data,
  });
};
export const getData = async (
  request: Request,
  url: string,
  responseType?: string,
) => {
  return await request({
    url: url,
    method: 'get',
    responseType: responseType,
  });
};
