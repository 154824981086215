import { Card, Col, Row, Tag } from 'antd';
import { FC, useState } from 'react';
import EndPoints from '../../shared/endPoints.json';
import BaseTable from '../../components/BaseTable/BaseTable';
import { dateEpochToGR } from '../../shared/services/helperFunctions';
import { baseStyle } from '../../shared/styles/baseStyle';
import useCreateAxiosInstance from '../../shared/hooks/useCreateAxiosInstance';
import { useQuery } from '@tanstack/react-query';
import { getData } from '../../shared/services/apiServices';
const UsersCatalogue: FC = () => {
  const { request } = useCreateAxiosInstance();
  const defaultCurrentPage = 1;
  const pageSize = 10;
  const defaultUrl = `${EndPoints.USER.USER}?Items=${pageSize}&Page=${defaultCurrentPage}`;
  const [url, setUrl] = useState(defaultUrl);

  const columns = [
    {
      title: 'Όνομα',
      dataIndex: 'firstName',
      key: 'firstName',
    },
    {
      title: 'Επίθετο',
      dataIndex: 'lastName',
      key: 'lastName',
    },

    {
      title: 'Username (e-mail)',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'ΑΦΜ',
      dataIndex: 'vatNumber',
      key: 'vatNumber',
    },
    {
      title: 'Τηλέφωνο',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Οδός',
      dataIndex: 'addressName',
      key: 'addressName',
    },
    {
      title: 'Αριθμός',
      dataIndex: 'addressNumber',
      key: 'addressNumber',
    },
    {
      title: 'Περιοχή',
      dataIndex: 'district',
      key: 'district',
    },
    {
      title: 'Δήμος',
      dataIndex: 'municipality',
      key: 'municipality',
    },
    {
      title: 'Τ.Κ',
      dataIndex: 'postalCode',
      key: 'postalCode',
    },
    {
      title: 'Επιλογή Πακέτου',
      dataIndex: 'subscriptionPackage',
      key: 'subscriptionPackage',
    },
    {
      title: 'Έναρξη Συνδρομής',
      dataIndex: 'subscriptionDateStarted',
      key: 'subscriptionDateStarted',
      render: (epochTime: string) => dateEpochToGR(+epochTime),
    },
    {
      title: 'Προέλευση ',
      dataIndex: 'userOrigin',
      key: 'userOrigin',
    },
    {
      title: 'Ρόλος',
      dataIndex: 'role',
      key: 'role',
      render: (role: string) => {
        return (
          <Tag color={role == 'Καλλιεργητής' ? 'green' : 'geekblue'}>
            {role}
          </Tag>
        );
      },
    },
    {
      title: 'Σημειώσεις',
      dataIndex: 'notes',
      key: 'notes',
    },
  ];
  const { data: users, isLoading } = useQuery({
    queryKey: ['users', url],
    queryFn: () => getData(request, url),
    select(data) {
      return data.data;
    },
  });
  const onChange = (pagination: any) => {
    setUrl(
      `${EndPoints.USER.USER}?Items=${pageSize}&Page=${pagination.current}`,
    );
  };
  return (
    <Row align="middle" justify="center" style={baseStyle.mainRow}>
      <Col span={22}>
        <Card>
          <BaseTable
            dataSource={users?.items}
            columns={columns}
            loading={isLoading}
            paginationConfig={{ pageSize: pageSize, total: users?.totalCount }}
            onChange={onChange}
            rowSelection={null}
          />
        </Card>
      </Col>
    </Row>
  );
};
export default UsersCatalogue;
