import { FC, useState } from 'react';
import { Row, Col } from 'antd';
import { baseStyle } from '../../shared/styles/baseStyle';
import endPoints from '../../shared/endPoints.json';
import BaseTable from '../BaseTable/BaseTable';
import { LandParcel } from '../../types/types';
import { useQuery } from '@tanstack/react-query';
import { getData } from '../../shared/services/apiServices';
import useCreateAxiosInstance from '../../shared/hooks/useCreateAxiosInstance';
import MapView from '../MapView/MapView';
import { mapCol } from './styles';
type Props = {
  selectedCultivation: LandParcel | undefined;
  setSelectedCultivation: React.Dispatch<
    React.SetStateAction<LandParcel | undefined>
  >;
};
const CalendarCultivations: FC<Props> = ({
  setSelectedCultivation,
  selectedCultivation,
}) => {
  const { request } = useCreateAxiosInstance();
  const defaultCurrentPage = 1;
  const pageSize = 5;
  const defaultUrl = `${endPoints.CULTIVATION.LANDPARCEL}?Items=${pageSize}&Page=${defaultCurrentPage}`;
  const [url, setUrl] = useState(defaultUrl);
  const { data: landParcels, isLoading } = useQuery({
    queryKey: ['landParcels', url],
    queryFn: () => getData(request, url),
    select(data) {
      return data.data;
    },
  });
  const onChange = (pagination: any) => {
    setUrl(
      `${endPoints.CULTIVATION.LANDPARCEL}?Items=${pageSize}&Page=${pagination.current}`,
    );
  };

  const columns = [
    {
      title: 'Τίτλος',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Καλλιέργεια',
      dataIndex: 'cultivation',
      key: 'cultivation',
    },
    {
      title: 'Τοπωνύμιο',
      dataIndex: 'placeName',
      key: 'placeName',
    },
    {
      title: 'Δήμος',
      dataIndex: 'municipality',
      key: 'municipality',
    },
    {
      title: 'Καλλιεργητής',
      dataIndex: 'user',
      key: 'user',
    },
    {
      title: 'Γεωπόνος',
      dataIndex: 'parent',
      key: 'parent',
    },
  ];

  return (
    <Row align="middle" justify="center" style={baseStyle.mainRow}>
      <Col span={24} style={mapCol}>
        <MapView
          height={'35vh'}
          coordinates={selectedCultivation?.coordinates}
          setPolygon={undefined}
          showDrawControls={false}
          landParcelMutation={undefined}
          clearCoordinates={undefined}
        />
      </Col>
      <Col span={24}>
        <BaseTable
          dataSource={landParcels?.items}
          columns={columns}
          paginationConfig={{
            pageSize: pageSize,
            total: landParcels?.totalCount,
          }}
          loading={isLoading}
          rowSelection={{
            type: 'radio',
            onSelect: (record: LandParcel) => {
              setSelectedCultivation(record);
            },
          }}
          onChange={onChange}
        />
      </Col>
    </Row>
  );
};
export default CalendarCultivations;
