import { FC, useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import { baseStyle } from '../../shared/styles/baseStyle';
import BaseTable from '../BaseTable/BaseTable';
import useCreateAxiosInstance from '../../shared/hooks/useCreateAxiosInstance';
import { useQuery } from '@tanstack/react-query';
import { getData } from '../../shared/services/apiServices';
type Props = {
  activityPostEndpoint: string;
  columns: any[];
  selectedCultivationId: number | undefined;
  activityAddition: boolean;
};
const ActivitiesTable: FC<Props> = ({
  activityPostEndpoint,
  columns,
  selectedCultivationId,
  activityAddition,
}) => {
  const defaultCurrentPage = 1;
  const pageSize = 5;
  const defaultUrl = `${activityPostEndpoint}?LandParcelId=${selectedCultivationId}&Items=${pageSize}&Page=${defaultCurrentPage}`;
  const [activityUrl, setActivityUrl] = useState(defaultUrl);
  const { request } = useCreateAxiosInstance();
  const { data: activities, isLoading } = useQuery({
    queryKey: ['activities', activityUrl, activityAddition],
    queryFn: () => getData(request, activityUrl),
    select(data) {
      return data.data;
    },
  });
  useEffect(() => {
    setActivityUrl(
      `${activityPostEndpoint}?LandParcelId=${selectedCultivationId}&Items=${pageSize}&Page=${defaultCurrentPage}`,
    );
  }, [selectedCultivationId]);
  const onChange = (pagination: any) => {
    setActivityUrl(
      `${activityPostEndpoint}?LandParcelId=${selectedCultivationId}&Items=${pageSize}&Page=${pagination.current}`,
    );
  };
  return (
    <Row align="middle" justify="center" style={baseStyle.mainRow}>
      <Col span={24}>
        <BaseTable
          dataSource={activities?.items}
          columns={columns}
          paginationConfig={{
            pageSize: pageSize,
            total: activities?.totalCount,
          }}
          loading={isLoading}
          rowSelection={null}
          onChange={onChange}
        />
      </Col>
    </Row>
  );
};
export default ActivitiesTable;
