import { FC, useContext, useState } from 'react';
import { UploadOutlined, DeleteOutlined } from '@ant-design/icons';
import type { UploadProps } from 'antd';
import { Button, Card, message, Row, Space, Upload } from 'antd';
import { AuthContext } from '../../shared/context/auth-context';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getData, mutateData } from '../../shared/services/apiServices';
import useCreateAxiosInstance from '../../shared/hooks/useCreateAxiosInstance';
import DownloadFile from './DownloadFile';
import endPoints from '../../shared/endPoints.json';

type Props = {
  selectedCultivationId: number | undefined;
  pdfFileName: string | null;
};

const UploadFile: FC<Props> = ({ selectedCultivationId, pdfFileName }) => {
  const { request } = useCreateAxiosInstance();
  const { token } = useContext(AuthContext);
  const [fileName, setFileName] = useState(pdfFileName);
  const basePdfUrl = `${endPoints.CULTIVATION.LANDPARCEL}/${selectedCultivationId}/${endPoints.CULTIVATION.PDF}`;

  const { isLoading } = useQuery({
    queryKey: ['pdf', selectedCultivationId],
    queryFn: () => getData(request, basePdfUrl),
    enabled: !!pdfFileName,
  });

  const { mutate } = useMutation({
    mutationFn: () =>
      mutateData(request, basePdfUrl, { id: selectedCultivationId }, 'delete'),
    onSuccess() {
      setFileName(null);
      message.success(`Το αρχείο διαγράφηκε με επιτυχία!`);
    },
  });

  const props: UploadProps = {
    name: 'PdfFile',
    accept: '.pdf',
    action: `${endPoints.BASEURL}${basePdfUrl}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    maxCount: 1,
    showUploadList: {
      showRemoveIcon: false,
      showPreviewIcon: false,
      showDownloadIcon: false,
    },
    onChange(info) {
      if (info.file.status === 'done') {
        message.success(`Το ${info.file.name} ανέβηκε με επιτυχία`);
        setFileName(info.file.name);
      } else if (info.file.status === 'error') {
        message.error(`Το ανέβασμα του αρχείου απέτυχε`);
      }
    },
    progress: {
      strokeColor: {
        '0%': '#108ee9',
        '100%': '#87d068',
      },
      strokeWidth: 5,
      strokeLinecap: 'round',
    },
    onRemove() {
      mutate();
    },
  };
  return (
    <Card loading={isLoading}>
      {fileName && (
        <>
          <Row>
            <Space>
              <DownloadFile
                selectedCultivationId={selectedCultivationId}
                pdfFileName={fileName}
              />
              <Button
                type="text"
                icon={<DeleteOutlined />}
                onClick={() => {
                  mutate();
                }}
              ></Button>
            </Space>
          </Row>
          <Upload {...props}>
            <Button icon={<UploadOutlined />} type="text">
              Ανανέωση Αρχείου
            </Button>
          </Upload>
        </>
      )}
      {!fileName && (
        <Upload {...props}>
          <Button icon={<UploadOutlined />} type="text">
            Ανέβασμα Αρχείου
          </Button>
        </Upload>
      )}
    </Card>
  );
};

export default UploadFile;
