import { FC } from 'react';
import { dateEpochToGR } from '../../shared/services/helperFunctions';
import ActivitiesTable from './ActivitiesTable';
import { LandParcel } from '../../types/types';
import endpoints from '../../shared/endPoints.json';
type Props = {
  selectedCultivation: LandParcel | undefined;
  activityAddition: boolean;
};
const QualityCharacteristicsTable: FC<Props> = ({
  selectedCultivation,
  activityAddition,
}) => {
  const columns = [
    {
      title: 'Ημερομηνία μέτρησης',
      dataIndex: 'measurementDate',
      key: 'measurementDate',
      render: (epochTime: string) => dateEpochToGR(+epochTime),
    },
    {
      title: 'Σάκχαρα καρπού',
      dataIndex: 'fruitSugars',
      key: 'fruitSugars',
    },
    {
      title: 'Σκληρότητα καρπού (χλγ)',
      dataIndex: 'fruitHardness',
      key: 'fruitHardness',
    },
    {
      title: 'Χρώμα καρπού',
      dataIndex: 'fruitColor',
      key: 'fruitColor',
    },
    {
      title: 'Αναμενόμενη Ημ/νία έναρξης συγκομιδής',
      dataIndex: 'expectedHarvestStartDate',
      key: 'expectedHarvestStartDate',
      render: (epochTime: string) => dateEpochToGR(+epochTime),
    },
    {
      title: 'Αναμενόμενη Ημ/νία λήξης συγκομιδής',
      dataIndex: 'expectedHarvestEndDate',
      key: 'expectedHarvestEndDate',
      render: (epochTime: string) => dateEpochToGR(+epochTime),
    },
    {
      title: 'Ημερομηνία επιτρεπόμενης συγκομιδής',
      dataIndex: 'allowableHarvestDate',
      key: 'allowableHarvestDate',
      render: (epochTime: string) => dateEpochToGR(+epochTime),
    },
  ];

  return (
    <ActivitiesTable
      activityPostEndpoint={endpoints.CALENDAR.LANDPARCELQUALITYCHARACTERISTIC}
      columns={columns}
      selectedCultivationId={selectedCultivation?.id}
      activityAddition={activityAddition}
    />
  );
};
export default QualityCharacteristicsTable;
