import { FC } from 'react';
import { Col, Row, Table } from 'antd';
import { useMutationState } from '@tanstack/react-query';
type Props = {
  g3MutationSettled: boolean;
};
const g3Table: FC<Props> = ({ g3MutationSettled }) => {
  const mutationKey = ['g3'];
  const data: any = useMutationState({
    filters: { mutationKey },
    select: (mutation) => mutation.state.data,
  });
  const latestG3 = data[data?.length - 1]?.data?.results;
  const transformOutput = (output: any) => {
    if (!output) return;
    return [
      {
        row: 'Άμεσες και έμμεσες εκπομπές GHG από τη διαχείριση εδαφών',
        cO2: output.cO2fromGround,
        n2O: output.n2OfromGround,
        cH4: output.cH4fromGround,
        cO2eq: output.cO2eqfromGround,
        cO2ton: output.cO2eqtonfromGround,
        cO2ha: output.cO2eqhafromGround,
        key: '1',
      },
      {
        row: 'Εκπομπές GHG (λιπάσματα, φυτοφάρμακα, σπόροι)',
        cO2: output.cO2fromSustances,
        cO2eq: output.cO2eqfromSustances,
        cO2ton: output.cO2eqtonfromSustances,
        cO2ha: output.cO2eqhafromSustances,
        key: '2',
      },
      {
        row: 'Εκπομπές GHG (μηχανήματα)',
        cO2: output.cO2fromMachinery,
        cO2eq: output.cO2eqfromMachinery,
        cO2ton: output.cO2eqtonfromMachinery,
        cO2ha: output.cO2eqhafromMachinery,
        key: '3',
      },
      {
        row: 'Μεταβολές στα αποθέματα άνθρακα ',
        cO2: output.cO2fromSOC,
        cO2eq: output.cO2eqfromSOC,
        cO2ton: output.cO2eqtonfromSOC,
        cO2ha: output.cO2eqhafromSOC,
        key: '4',
      },
    ];
  };
  const columns: any = [
    {
      title: '',
      dataIndex: 'row',
      key: 'row',
      rowScope: 'row',
    },
    {
      title: 'Διοξείδιο του άνθρακα (κιλά)',
      dataIndex: 'cO2',
      key: 'cO2',
    },
    {
      title: 'Υποξείδιο του αζώτου (κιλά)',
      dataIndex: 'n2O',
      key: 'n2O',
    },
    {
      title: 'Μεθάνιο (κιλά)',
      dataIndex: 'cH4',
      key: 'cH4',
    },
    {
      title: 'Ισοδύναμα διοξειδίου του άνθρακα (κιλά)',
      dataIndex: 'cO2eq',
      key: 'cO2eq',
    },
    {
      title: 'Ισοδύναμο διοξειδίου του άνθρακα (κιλά/τόνο προϊόντος)',
      dataIndex: 'cO2ton',
      key: 'cO2ton',
    },
    {
      title: 'Ισοδύναμο διοξειδίου του άνθρακα (κιλά/εκτάριο)',
      dataIndex: 'cO2ha',
      key: 'cO2ha',
    },
  ];
  if (!g3MutationSettled) return <></>;
  return (
    <Row>
      <Col>
        <Table
          dataSource={transformOutput(latestG3)}
          columns={columns}
          size="small"
          pagination={false}
          style={{ overflowX: 'auto' }}
          loading={!latestG3}
        />
      </Col>
    </Row>
  );
};
export default g3Table;
