import { FC } from 'react';
import CultivationForm from '../../components/Forms/Cultivation/CultivationForm';
import { CultivationDetails } from '../../types/types';

type Props = {
  selectedCultivation: CultivationDetails;
  setIsEditCatalogueModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};
const EditCultivation: FC<Props> = ({
  selectedCultivation,
  setIsEditCatalogueModalOpen,
}) => {
  if (!selectedCultivation) return <></>;

  return (
    <CultivationForm
      selectedCultivationDetails={selectedCultivation}
      apiMethod="put"
      successTitle="Το αγροτεμάχιο επεξεργάστηκε με επιτυχία!"
      setIsEditCatalogueModalOpen={setIsEditCatalogueModalOpen}
      viewOnly={false}
    />
  );
};

export default EditCultivation;
