import {
  Button,
  Col,
  DatePicker,
  Form,
  FormProps,
  Input,
  InputNumber,
  Row,
  Select,
  message,
} from 'antd';
import { FC, useEffect } from 'react';
import { NamedItem, LandParcel, FertilizerType } from '../../../types/types';
import { baseFormLayout, fullWidth } from '../../../shared/styles/baseStyle';
import LoadingSpin from '../../LoadingSpin/LoadingSpin';
import { getData, mutateData } from '../../../shared/services/apiServices';
import useCreateAxiosInstance from '../../../shared/hooks/useCreateAxiosInstance';
import { useMutation, useQuery } from '@tanstack/react-query';

import endPoints from '../../../shared/endPoints.json';
type Props = {
  selectedCultivation: LandParcel | undefined;
  setActivityAddition: React.Dispatch<React.SetStateAction<boolean>>;
  activityAddition: boolean;
};
const FertilizerForm: FC<Props> = ({
  selectedCultivation,
  setActivityAddition,
  activityAddition,
}) => {
  const { request } = useCreateAxiosInstance();
  const [form] = Form.useForm();
  const dateFormat = 'DD/MM/YYYY';
  const requiredRuleConfig = {
    required: true,
    message: 'Υποχρεωτικό πεδίο',
  };
  useEffect(() => {
    form.setFieldsValue({
      cultivation: selectedCultivation?.cultivation,
      landParcelId: selectedCultivation?.id,
    });
  }, [selectedCultivation]);
  const { data: fertilizerModeItems, isLoading: fertilizerModeLoading } =
    useQuery({
      queryKey: ['fertilizerModeItems'],
      queryFn: () => getData(request, endPoints.CALENDAR.FERTILIZERMODE),
      select(data: { data: NamedItem[] }) {
        return data.data.map((item: NamedItem) => ({
          value: item.id,
          label: item.name,
        }));
      },
    });
  const { data: fertilizerWeatherItems, isLoading: fertilizerWeatherLoading } =
    useQuery({
      queryKey: ['fertilizerWeatherItems'],
      queryFn: () => getData(request, endPoints.CALENDAR.FERTILIZERWEATHER),
      select(data: { data: NamedItem[] }) {
        return data.data.map((item: NamedItem) => ({
          value: item.id,
          label: item.name,
        }));
      },
    });
  const { data: fertilizerTypeItems, isLoading: fertilizerTypeLoading } =
    useQuery({
      queryKey: ['fertilizerTypeItems'],
      queryFn: () => getData(request, endPoints.CALENDAR.FERTILIZERTYPE),
      select(data: { data: FertilizerType[] }) {
        return data.data.map((item: FertilizerType) => ({
          value: item.id,
          label: item.nameGr,
        }));
      },
    });
  const {
    data: FertilizerApplicationMethodItems,
    isLoading: fertilizerApplicationMethodLoading,
  } = useQuery({
    queryKey: ['fertilizerApplicationMethodItems'],
    queryFn: () =>
      getData(request, endPoints.CALENDAR.FERTILIZERAPPLICATIONMETHOD),
    select(data: { data: NamedItem[] }) {
      return data.data.map((item: NamedItem) => ({
        value: item.id,
        label: item.name,
      }));
    },
  });
  const {
    data: fertilizerApplicationFrequencyItems,
    isLoading: fertilizerApplicationFrequencyLoading,
  } = useQuery({
    queryKey: ['fertilizerApplicationFrequencyItems'],
    queryFn: () =>
      getData(request, endPoints.CALENDAR.FERTILIZERAPPLICATIONFREQUENCY),
    select(data: { data: NamedItem[] }) {
      return data.data.map((item: NamedItem) => ({
        value: item.id,
        label: item.name,
      }));
    },
  });

  const { mutate } = useMutation({
    mutationFn: (formValues) =>
      mutateData(
        request,
        endPoints.CALENDAR.LANDPARCELFERTILIZER,
        formValues,
        'post',
      ),
    onSuccess() {
      message.success('Η ενέργεια προστέθηκε με επιτυχία!');
      form.resetFields([
        'applicationDate',
        'fertilizerWeatherId',
        'fertilizerTypeId',
        'fertilizerDose',
        'fertilizerModeId',
        'fertilizerApplicationFrequencyId',
        'fertilizerApplicationMethodId',
      ]);
      setActivityAddition(!activityAddition);
    },
  });

  const onFinish: FormProps['onFinish'] = async (values: any) => {
    const formattedValues = {
      ...values,
      applicationDate: values.applicationDate?.$d.getTime(),
    };
    mutate(formattedValues);
  };
  if (
    fertilizerModeLoading ||
    fertilizerWeatherLoading ||
    fertilizerApplicationFrequencyLoading ||
    fertilizerApplicationMethodLoading ||
    fertilizerTypeLoading
  )
    return <LoadingSpin />;
  return (
    <Form
      {...baseFormLayout}
      layout="vertical"
      size="large"
      form={form}
      onFinish={onFinish}
    >
      <Row>
        <Col xs={12}>
          <Form.Item name="landParcelId" label="Κωδικός Αγροτεμαχίου">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col xs={12}>
          <Form.Item name="cultivation" label="Καλλιέργεια">
            <Input disabled />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Form.Item
            name="applicationDate"
            label="Ημ/νια Εφαρμογής"
            rules={[requiredRuleConfig]}
          >
            <DatePicker
              format={dateFormat}
              placeholder="Ημ/νια Εφαρμογής"
              style={fullWidth}
            />
          </Form.Item>
        </Col>
        <Col xs={12}>
          <Form.Item
            name="fertilizerWeatherId"
            label="Καιρός κατά την Εφαρμογή"
            rules={[requiredRuleConfig]}
          >
            <Select
              allowClear
              options={fertilizerWeatherItems}
              placeholder="Καιρός κατά την Εφαρμογή"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Form.Item
            name="fertilizerTypeId"
            label="Τύπος Λιπάσματος"
            rules={[requiredRuleConfig]}
          >
            <Select
              allowClear
              options={fertilizerTypeItems}
              placeholder="Τύπος Λιπάσματος"
            />
          </Form.Item>
        </Col>
        <Col xs={12}>
          <Form.Item
            name="fertilizerDose"
            label="Δόση Λίπανσης (kg/ha ή lt/ha)"
            rules={[requiredRuleConfig]}
          >
            <InputNumber
              type="number"
              style={fullWidth}
              step={1}
              min={0}
              placeholder="Δόση Λίπανσης"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="start">
        <Col xs={12}>
          <Form.Item
            name="fertilizerModeId"
            label="Τρόπος Εφαρμογής"
            rules={[requiredRuleConfig]}
          >
            <Select
              allowClear
              options={fertilizerModeItems}
              placeholder="Τρόπος Εφαρμογής"
            />
          </Form.Item>
        </Col>
        <Col xs={12}>
          <Form.Item
            name="fertilizerApplicationMethodId"
            label="Μέθοδος Εφαρμογής"
            rules={[requiredRuleConfig]}
          >
            <Select
              allowClear
              options={FertilizerApplicationMethodItems}
              placeholder="Τρόπος Εφαρμογής"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="start">
        <Col xs={12}>
          <Form.Item
            name="fertilizerApplicationFrequencyId"
            label="Συχνότητα Εφαρμογής"
            rules={[requiredRuleConfig]}
          >
            <Select
              allowClear
              options={fertilizerApplicationFrequencyItems}
              placeholder="Συχνότητα Εφαρμογής"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="center">
        <Button htmlType="submit">Προσθήκη</Button>
      </Row>
    </Form>
  );
};
export default FertilizerForm;
