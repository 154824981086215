import { Menu } from 'antd';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
type Props = {
  items: any;
};
const SiderMenu: FC<Props> = ({ items }) => {
  const navigate = useNavigate();
  const farmabaseUrl =
    'https://www.farmacon.gr/fytoprostateutika-proionta?view=agripro&logiclogin=basicUserFARMALOGICPkcGHIcIqvpgWrxmVniQrCwrWAU35AK8:LOGICFARMA:5d7a245a05a10eb593dff9612bc819cc';
  return (
    <Menu
      theme="dark"
      mode="inline"
      items={items}
      onClick={(e) => {
        if (e.key === 'pesticides') {
          window.location.href = farmabaseUrl;
          return;
        }
        navigate(e.key);
      }}
    />
  );
};
export default SiderMenu;
